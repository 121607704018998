import Banner from '../../assets/cruise_page/cruise-banner.jpg'
import DroneShot from '../../assets/cruise_page/cruise-drone-shot.jpg'
import Food from '../../assets/cruise_page/cruise-food.jpg'
import Dining from '../../assets/cruise_page/dining.jpg'
import RoofDine from '../../assets/cruise_page/roof-dining.jpg'
import RoofTop from '../../assets/cruise_page/roof-top.jpg'
import Couch from '../../assets/cruise_page/couch.jpg'
import Outdoor from '../../assets/cruise_page/outdoor.jpg'
import Steps from '../../assets/cruise_page/steps.jpg'
import Yacht from '../../assets/cruise_page/yacht.jpg'
import Amenities from '../../assets/cruise_page/cruise-amenities.jpg'
import Cruise from '../../assets/cruise_page/cruise.jpg'
import Calendar from './../../assets/icons/header-icons/monthly-calendar-svgrepo-com.svg';
import './../../CssComponent/HomePageStyle.css'
import DatePicker from 'react-datepicker';
import Search from './../../assets/icons/visa-page-images/search.png';
import { Helmet } from 'react-helmet';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import TopHeader from './top_header'

import SideSocialMedia from '../SocialMedia/SocialMediaSide';

import { useState } from 'react';

function CruisePage() {

  // ------------------------------------------------------

  const [startDate, setStartDate] = useState(new Date());

  const handleStartDateChange = (date) => {
    setStartDate(date);
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
  };

  // ------------------------------------------------------

  // Handle form submission
  const handleSearch = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    const phoneNumber = '+971544544971';
    const whatsappLink = `https://wa.me/${phoneNumber}`;

    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };
  return (
    <div className="h-[100%] dark:text-black pb-[5%]">
      <Helmet>
        <title>Book Cruises and Yachts in UAE | Shaliha Tourism</title>
        <meta
          name="description"
          content="Explore the beauty of the UAE with our cruise and yacht booking services. Shaliha Tourism offers unforgettable experiences in Dubai, Abu Dhabi, Ajman, and more. Book your dream cruise or yacht adventure with us today."
        />
      </Helmet>
      <TopHeader/>
      <SideSocialMedia/>
      <div className='h-[100vh] relative'>
        <img alt=" " src={Banner} className='h-[100vh] w-[100vw] drop-shadow-lg' />
        <div className='absolute top-[15%] text-white w-[75%] left-[10%] text-center  playfair-font'>
          <h3 className='text-7xl 3xl:text-7xl 2xl:text-6xl xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl xs:text-xl font-semibold'>Sail into Splendor</h3>
          <p className='text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-lg xs:text-sm mt-4'>Where Every Wave Beckons Adventure and Luxury Awaits</p>
          <p className='text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-lg xs:text-sm mt-[2.5%] '>Your Premier Yacht and Cruise Booking Service in the UAE. Elevate Your Maritime Experience with Us!</p>
          {/* ----------------------BIG SCREEN------------------ */}
          <div className='bg-white opacity-90 mx-auto w-[80%] 3xl:w-[80%] 2xl:w-[80%] xl:w-[100%] lg:w-[100%]  p-2 rounded-xl drop-shadow-xl mt-[5%] 3xl:block 2xl:block xl:block lg:block md:hidden sm:hidden xs:hidden z-10'>
            <form className='flex items-center   text-black' onSubmit={handleSearch}>
              <input type='text' placeholder='Search City' className='dark:bg-white input 3xl:border-0 focus:outline-0 2xl:border-0 xl:border-0 lg:border-0 md:input-bordered text-lg poppins 3xl:w-[75%] 2xl:w-[75%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] text-lg 3xl:text-lg 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs  ' />
              <div className='input-container  3xl:w-[25%] 2xl:w-[25%] xl:w-[25%] lg:w-[25%] md:w-[25%] sm:w-[25%] xs:w-[25%] text-lg 3xl:text-lg 2xl:text-sm xl:text-sm lg:text-sm md:text-xs sm:text-xs xs:text-xs' id="date-picker-container">
                <label for="date-from" className='flex gap-3 uppercase poppins items-center not-italic'><img alt=" " src={Calendar} width={20} />travel Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="EEE MMM d, yyyy"
                  className="border rounded-md  py-2 outline-none  poppins z-10 "
                />
              </div>
              <button type='submit' className='bg-[#0077b3] w-[25%] text-white rounded-xl drop-shadow-xl py-5 drop-shadow-xl'>Search</button>
            </form>
          </div>
          {/* ----------------------SMALL SCREEN------------------ */}
          <div className='3xl:hidden 2xl:hidden xl:hidden lg:hidden md:block sm:block xs:block w-[100%] text-black mt-5'>
            <form className=' text-lg 3xl:text-lg 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs  ' onSubmit={handleSearch}>
              <input type='text' placeholder='Search City' className='input input-bordered w-full w-max-xs rounded-full dark:bg-white text-lg poppins 3xl:w-[75%] 2xl:w-[75%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] text-lg 3xl:text-lg 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs border-r-2 ' />
              <div className='input-container flex items-center between gap-[15%] mt-5 bg-white  input input-bordered w-full w-max-xs rounded-full p-2 px-3 text-lg  md:text-sm sm:text-sm xs:text-xs' id="date-picker-container">
                <label for="date-from" className='flex gap-3 uppercase poppins items-center not-italic whitespace-nowrap'><img alt=" " src={Calendar} width={20} />Select date</label>
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="EEE MMM d, yyyy"
                  className="border rounded-md  outline-none  poppins z-10 "
                />
              </div>
              <div className='flex justify-center'>
                <button type='submit' className='mt-5 ' ><img alt=" " src={Search} className='customInput mr-0' /></button>
              </div>

            </form>
          </div>

        </div>
      </div>
      <div className='flex px-[5%] mt-[5%] gap-5 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col'>
        <div className='w-[40%] 3xl:w-[40%] 2xl:w-[40%] flex 3xl:flex-row 2xl:flex-row xl:flex-col lg:flex-col md:flex-row sm:flex-row xs:flex-row '>
          <img alt=" " src={DroneShot} className='w-[500px] h-[350px] 3xl:w-[500px] 3xl:h-[300px] 2xl:w-[300px] 2xl:h-[250px] xl:h-[200px] xl:w-[200px] lg:h-[200px] lg:w-[200px] md:w-[200px] md:h-[200px] sm:w-[200px] sm:h-[200px] xs:w-[200px] xs:h-[200px] rounded-[15%] ' />
          <img alt=" " src={Amenities} className='w-[500px] h-[300px] 3xl:w-[500px] 3xl:h-[350px] 2xl:w-[300px] 2xl:h-[250px] xl:w-[200px] xl:h-[250px] lg:h-[200px] lg:w-[200px] md:w-[200px] md:h-[200px] sm:w-[200px] sm:h-[200px] xs:w-[200px] xs:h-[200px] rounded-[25%]  border-[12px] mt-[15%] border-[#e5f9fe] ml-[-12%] 3xl:ml-[-12%] 2xl:ml-[-12%] xl:ml-[35%] lg:ml-[35%] md:ml-[-15%] sm:ml-[-15%] xs:ml-[-15%] 3xl:mt-[15%] 2xl:mt-[15%] xl:mt-[-20%] lg:mt-[-20%] md:mt-[50%] sm:mt-[50%] xs:mt-[50%] z-10 ' />
          <img alt=" " src={Cruise} className='w-[300px] h-[300px] 3xl:w-[300px] 3xl:h-[300px] 2xl:w-[200px] 2xl:h-[250px]  xl:w-[200px] xl:h-[200px] lg:h-[200px] lg:w-[200px] md:w-[200px] md:h-[200px] sm:w-[200px] sm:h-[200px] xs:w-[200px] xs:h-[200px] border-[10px]   border-[#e5f9fe]  ml-[-10%] 3xl:ml-[-10%] 2xl:ml-[-10%] xl:ml-[-10%] lg:ml-[-10%] md:ml-[-25%] sm:ml-[-25%] xs:ml-[-25%] 3xl:mt-0 2xl:mt-0 xl:mt-[-25%] lg:mt-[-25%] md:mt-[12%] sm:mt-[12%] xs:mt-[12%] rounded-[15%] z-0' />
        </div>
        <div className='px-[5%] py-[2%] text-left w-[60%] 3xl:w-[60%] 2xl:w-[60%] xl:w-[60%] lg:w-[60%] md:w-[100%] sm:w-[100%] xs:w-[100%] italic '>
          <h5 className='text-[#3D85C6] text-3xl 3xl:text-3xl 2xl:text-2xl xl:text-2xl lg:text-xl md:text-xl sm:text-xl xs:text-xl poppins font-semibold'>Premier Yacht and Cruise Bookings in the UAE</h5>
          <p className='text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg xs:text-lg text-stone-600 font-semibold mt-3'>Let Us Find the Ideal Option for Your Unique Requirements</p>
          <p className='mt-5 text-lg poppins 3xl:text-lg 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm 3xl:leading-[200%] 2xl:leading-[200%] xl:leading-[200%] lg:leading-[175%] md:leading-[200%] sm:leading-[200%] xs:leading-[200%]'>Embark on a luxurious voyage across the UAE with our yacht and cruise offerings. Experience unmatched elegance, breathtaking views, and impeccable service—all at an affordable package with exclusive discounts. Book now and elevate your maritime adventure with savings that make waves. Your unforgettable journey begins here!</p>
        </div>
      </div>
      <div className='bg-white p-[5%] pb-[10%] mt-[5%] drop-shadow-lg'>
        <h3 className='text-[#3D85C6] text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-3xl sm:text-3xl xs:text-3xl poppins font-semibold'>Cruise Buffet</h3>
        <div className='grid grid-cols-5 3xl:grid-cols-4 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-x-[2%] gap-y-[15%] 3xl:gap-x-[2%] 3xl:gap-y-[15%] 2xl:gap-x-[2%] 2xl:gap-y-[15%] xl:gap-x-[2%] xl:gap-y-[15%] lg:gap-x-[2%] lg:gap-y-[15%] md:gap-10 sm:gap-10 xs:gap-10 mt-[5%]'>
            <img alt=" " src={RoofTop} className='w-[500px] h-[250px] drop-shadow-lg' />
            <img alt=" " src={RoofDine} className='w-[500px] h-[250px] drop-shadow-lg' />
            <img alt=" " src={Food} className='w-[500px] h-[250px] drop-shadow-lg' />
            <img alt=" " src={Dining} className='w-[500px] h-[250px] drop-shadow-lg 3xl:block 2xl:block xl:hidden lg:hidden md:block sm:block xs:block' />
        </div>
      </div>
      <div className='mt-[5%] text-[#3D85C6] poppins px-[5%]'>
          <h1 className='text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xl sm:text-3xl xs:text-3xl font-semibold '>Yacht</h1>
          <p className='text-3xl 3xl:text-3xl 2xl:text-2xl xl:text-2xl lg:text-xl md:text-xl sm:text-xl xs:text-xl mt-5'>Embark on elegance, cruise through life with Yacht dreams setting sail.</p>
          <p className='text-xl 3xl:text-xl 2xl:text-lg xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-sm mt-5 3xl:leading-[200%] 2xl:leading-[200%] xl:leading-[200%] lg:leading-[175%] md:leading-[200%] sm:leading-[200%] xs:leading-[200%]'>Embark on a voyage of unparalleled luxury with Yachting – a seamless blend of sophistication and the open sea. Feel the gentle breeze as you cruise in style, creating unforgettable memories on the water. From lavish interiors to breathtaking views, every detail is tailored for an indulgent experience. Discover a world where elegance meets adventure, and let Yacht life redefine your sense of escape. Set sail into a realm of opulence, where each moment is a celebration of nautical bliss.</p>
        </div>
        <div className='bg-white p-[5%] mt-[5%] text-[#3D85C6] poppins'>
        <h1 className='text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xl sm:text-3xl xs:text-3xl font-semibold '>Explore More</h1>
        <div className='grid grid-cols-5 3xl:grid-cols-4 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-x-[2%] gap-y-[15%] 3xl:gap-x-[2%] 3xl:gap-y-[15%] 2xl:gap-x-[2%] 2xl:gap-y-[15%] xl:gap-x-[2%] xl:gap-y-[15%] lg:gap-x-[2%] lg:gap-y-[15%] md:gap-10 sm:gap-10 xs:gap-10 mt-[5%]'>
            <img alt=" " src={Yacht} className='w-[500px] h-[250px] drop-shadow-lg' />
            <img alt=" " src={Couch} className='w-[500px] h-[250px] drop-shadow-lg 3xl:block 2xl:block xl:hidden lg:hidden md:block sm:block xs:block' />
            <img alt=" " src={Steps} className='w-[500px] h-[250px] drop-shadow-lg ' />
            <img alt=" " src={Outdoor} className='w-[500px] h-[250px] drop-shadow-lg ' />
        </div>
        </div>
    </div>
  );
}

export default CruisePage;