
import Mountain from './../../assets/background-images/pexels-skitterphoto-919932.jpg'
import Arrow from '../../assets/right-arrow-white.svg'
function Banner() {
   // Handle form submission
   const handleSearch = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    const phoneNumber = '+971544544971';
    const whatsappLink = `https://wa.me/${phoneNumber}`;

    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };
  return (
    <div className="h-[100vh] relative">
        <img alt=" " src={Mountain} className='h-[100vh] w-[100vw] opacity-75' />
        <div className='absolute top-[5%] left-[5%] w-[90%] text-[#639dd1]'>
          <p className='image-text text-[100px] 3xl:text-[100px] 2xl:text-[85px] xl:text-[80px] lg:text-[75px] md:text-[45px] sm:text-[40px] xs:text-[35px] font-bold drop-shadow-2xl whitespace-nowrap'>SKY IS THE LIMIT</p>
          <div className='bg-white mt-5 py-[3%] px-0 3xl:px-0 2xl:px-0 xl:px-0 lg:px-0 md:px-5 sm:px-[7%] xs:px-5'>
          <p className='text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-3xl sm:text-3xl xs:text-3xl font-semibold uppercase '>Grab the deal</p>
          <p className='mt-5 text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-lg lg:text-lg sm:text-sm xs:text-sm font-semibold '>For 1 Adult visit visa - 1 Child visit visa free (UAE)</p>
          <p className='mt-5 text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-lg lg:text-lg sm:text-sm xs:text-sm font-semibold '>Claim your free pass to any 1 Tourist spot all over UAE <span className='text-lg 3xl:text-lg 2xl:text-lg xl:text-lg lg:text-sm  md:text-xs xs:text-xs'>[Selective spots]</span> </p>
          <p className='text-sm mt-5'>Terms and conditions apply</p>
          </div>
          <button onClick={handleSearch} className='text-white mt-[5%] text-3xl 3xl:text-3xl 2xl:text-2xl xl:text-2xl lg:text-xl md:text-xl sm:text-xl xs:text-xl font-semibold bg-[#3D85C6]  justify-center drop-shadow-xl hover:opacity-75 w-[30%] 3xl:w-[30%] 2xl:w-[30%] xl:w-[30%] lg:w-[40%] md:w-[80%] sm:w-[80%] xs:w-[80%] flex mx-auto gap-5 items-center' >Get Now <img alt=" " src={Arrow} className = "w-[70px]" /></button>
        </div>
      {/* <div className='' >
      <img alt=" " src={Dubai} className='relative overflow-hidden w-[100vw] h-[100vh]' />
      <img alt=" " src={DotsMenu} className='absolute top-[0.5%] left-[1%] w-[25px] lg:w-[20px] md:w-[12px] sm:w-[12px] xs:w-[12px] opacity-80'/>
      <img alt=" " src={DotsMenu} className='absolute top-[1.07%] 3xl:top-[0.84%] 2xl:top-[1.05%] xl:top-[1.15%] lg:top-[1.07%] md:top-[0.88%] sm:top-[0.88%] xs:top-[0.88%] left-[1%] w-[25px] lg:w-[20px] md:w-[12px] sm:w-[12px] xs:w-[12px] opacity-80'/>
      <img alt=" " src={DotsMenu} className='absolute top-[1.6%] 3xl:top-[1.18%] 2xl:top-[1.54%] xl:top-[1.8%] lg:top-[1.67%] md:top-[1.2%] sm:top-[1.28%] xs:top-[1.3%] left-[1%] w-[25px] lg:w-[20px] md:w-[12px] sm:w-[12px] xs:w-[12px] opacity-80'/>
      <img alt=" " src={SS} className='absolute top-[1%] opacity-80 w-[60px] 3xl:w-[60px] 2xl:w-[60px] xl:w-[50px] lg:w-[40px] md:w-[25px] xs:w-[20px] right-2 rotate-90' alt='icon'/>
      <div>
        <img alt=" " src={Triangle} className='absolute top-[1%] left-[5%] opacity-80 2xl:w-[25%] xl:w-[25%] lg:w-[22%] md:w-[21%] sm:w-[18%] xs:w-[18%] md:top-[0.5%] sm:top-[0.4%] sm:left-[9%] xs:top-[0.5%] xs:left-[9%]' />
        <img alt=" " src={Triangle} className='absolute top-[9%] 3xl:top-[7%] 3xl:left-[15%] left-[18%] 2xl:left-[17.5%] 2xl:top-[7%] 2xl:w-[25%]  xl:top-[6%] xl:w-[25%] lg:w-[22%] lg:top-[4%] lg:left-[15%] md:w-[21%] sm:w-[18%] xs:w-[18%] md:top-[2%] md:left-[12%] sm:top-[1.8%] xs:top-[1.8%] opacity-80 rotate-180' />
      </div>
      <div className=''>
        <h1 className=' bg-white w-[25%] 3xl:w-[25%] 2xl:w-[30%] xl:w-[40%] lg:w-[40%] md:w-[40%] sm:w-[40%] xs:w-[42%] absolute top-[9%] 3xl:top-[7%] 3xl:left-[14%] 2xl:top-[7%] 2xl:left-[14%] xl:top-[6%] xl:left-[17%] lg:top-[4%] lg:left-[15%] md:top-[1.7%] md:left-[14%] sm:top-[1.45%] xs:top-[1.45%] px-[3%] py-[1%] left-[18%] drop-shadow-xl skew-x-6 text-3xl 3xl:text-3xl 2xl:text-2xl xl:text-xl lg:text-lg md:text-sm sm:text-xs xs:text-xs font-semibold whitespace-nowrap'>Double the Joy, Half the Cost: </h1>
        <div className='bg-[#3D85C6] w-[35%] 3xl:w-[45%] 2xl:w-[57%] xl:w-[50%] lg:w-[60%] md:w-[80%] sm:w-[80%] xs:w-[88%] absolute top-[10.4%] 3xl:top-[8.34%] 3xl:left-[15.5%] 2xl:top-[8.5%] 2xl:left-[15.5%] xl:top-[7.4%] xl:left-[15%] lg:top-[5%] lg:left-[14%] md:top-[2.45%] md:left-[12%] sm:top-[2%] xs:top-[2%] xs:left-[11%] px-[3%] py-[1%] left-[18%] drop-shadow-xl skew-x-6 text-3xl font-semibold text-white flex items-center whitespace-nowrap 3xl:text-3xl 2xl:text-2xl xl:text-xl lg:text-lg md:text-sm sm:text-xs xs:text-xs'> 
        <h1>1 Adult Visa, 1 Child Visa Free – Your Family Adventure Awaits!</h1> 
        <span><img alt=" " src={Dots} className='w-[65px] 3xl:w-[65px] 2xl:w-[65px] xl:w-[45px] sm:w-[15px] xs:w-[35px] rotate-180'/></span> 
        </div>
      </div>
      <div className='bg-[#3D85C6] opacity-80 absolute top-[13%] 3xl:top-[13.3%] 2xl:top-[11.7%] xl:top-[10%] lg:top-[6.4%] md:top-[3.6%] sm:top-[3%] xs:top-[2.8%] right-0 text-white text-white text-3xl 3xl:text-4xl 2xl:text-xl xl:text-lg lg:text-sm md:text-xs sm:text-xs xs:text-xs font-semibold pl-5 w-[40%] 3xl:w-[40%] 2xl:w-[40%] xl:w-[50%] lg:w-[50%] md:w-[70%] sm:w-[70%] xs:w-[75%] text-left py-[1.5%] 3xl:py-[2%]'>Your Dream Getaway with Our Budget-Friendly Package Tourism!</div>
      </div> */}
      
    </div>
  );
}

export default Banner;