import Facebook from './../../assets/social-media/facebook.png';
import Instagram from './../../assets/social-media/instagram.png';
import Youtube from './../../assets/social-media/youtube.png';
import Tiktok from './../../assets/social-media/tik-tok.png';
import whatsapp from './../../assets/social-media/whatsapp.png';
function SideSocialMedia() {
  const phoneNumber = '+971544544971';
    return (
      <div className="fixed top-[25%] left-[1%] z-10">
        <div className='flex flex-col gap-5 self-center w-[40%] 2xl:w-[30%] 3xl:w-[40%] xl:w-[28%] lg:w-[30%] sm:w-[25%] xs:w-[25%]' >
          <a href='https://www.facebook.com/ShalihaTourism?mibextid=ZbWKwL' target="_blank" rel="noopener noreferrer" className='bg-[#3D85C6] p-3 2xl:py-[20%] 2xl:px-[-5%]  rounded-lg drop-shadow-xl dark:drop-shadow-sm'>
            <img alt=" " src={Facebook} className=' scale-100 hover:scale-125 ml-1 w-[75%] 3xl:w-[100%] 3xl:ml-0'/>
            </a>
            <a href='https://www.instagram.com/shaliha_tourism?igsh=OGQ5ZDc2ODk2ZA==' target="_blank" rel="noopener noreferrer" className='bg-[#3D85C6] p-3 rounded-lg drop-shadow-xl dark:drop-shadow-sm'>
            <img alt=" " src={Instagram} className=' scale-100 hover:scale-125 ml-[7%] w-[79%] 3xl:w-[100%]'/>
            </a>
            <a href='http://www.youtube.com/@SHALIHATOURISMLLC' target="_blank" rel="noopener noreferrer" className='bg-[#3D85C6] p-3 pb-[6%]  rounded-lg drop-shadow-xl dark:drop-shadow-sm justify-center '>
            <img alt=" " src={Youtube}  className=' scale-100 hover:scale-125 mt-[3%] ml-[5%] w-[90%] 3xl:w-[100%] '/>
            </a>
            <a href='https://www.tiktok.com/@shalihatourism2?_t=8ij0sGH0B5L&_r=1' target="_blank" rel="noopener noreferrer" className='bg-[#3D85C6] p-3 rounded-lg drop-shadow-xl dark:drop-shadow-sm'>
            <img alt=" " src={Tiktok}  className=' scale-100 hover:scale-125 ml-[10%] w-[75%] 3xl:w-[100%]'/>
            </a>
            <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer" className='bg-[#3D85C6] p-3 rounded-lg drop-shadow-xl dark:drop-shadow-sm'>
            <img alt=" "  src={whatsapp} className=' scale-100 hover:scale-125 ml-1 w-[90%] 3xl:w-[100%] 3xl:ml-0'/>
            </a>
        </div>
      </div>
    );
  }
  
  export default SideSocialMedia;