import Traveller from '../../assets/blog_page/traveller-2.jpg'
import Triangle from '../../assets/blog_page/white-triangle.png'
import Blue from '../../assets/blog_page/blue-triangle.png'
import Logo from '../../assets/blog_page/logo.png';
import '../../CssComponent/HomePageStyle.css'
import Bg from '../../assets/blog_page/bg.jpg'
import Building from '../../assets/blog_page/building.jpg'
import Beach from '../../assets/blog_page/beach.jpg'
import Mountains from '../../assets/blog_page/mountains.jpg'
import Flight from '../../assets/blog_page/direct-flight.png'
import Tickets from '../../assets/blog_page/ticket-flight.png'
import Send from '../../assets/blog_page/send.png'
import FlightWhite from '../../assets/blog_page/direct-flight-white.png'
import TicketsWhite from '../../assets/blog_page/ticket-flight-white.png'
import SendWhite from '../../assets/blog_page/send-white.png'
import Home from '../../assets/home.svg'

import { useNavigate } from 'react-router-dom';
function BlogBanner() {
  const navigate = useNavigate();
  const goToHome = () => {
    navigate('/');
};
    return (
      <div className="flex relative h-[100vh] justify-between pb-[5%] dark:text-black ">
        <button onClick={goToHome} className='absolute top-[5%] right-[2%] 3xl:right-[7%] 2xl:right-[3%] xl:right-[4%] lg:right-[7%] z-50 text-white  hover:border-b-2 border-white flex gap-2 items-start py-2'>Home<img src={Home} alt='' className='w-[20px]'/></button>
        <img src={Logo} className='w-[100px] h-[150px] 3xl:w-[100px] 3xl:h-[150px] 2xl:w-[100px] 2xl:h-[150px] xl:w-[80px] xl:h-[120px] lg:w-[75px] lg:h-[100px] mt-0 3xl:block 2xl:block xl:block lg:block md:hidden sm:hidden xs:hidden' alt='Shaliha Tourism' />
        <img src={Bg} className='relative w-[45%] h-[80%] 3xl:w-[45%] 3xl:h-[80%] 2xl:w-[45%] 2xl:h-[80%] xl:w-[50%] xl:h-[80%] lg:w-[50%] lg:h-[70%] md:w-[100vw] md:h-[100vh] sm:w-[100vw] sm:h-[100vh] xs:w-[100vw] xs:h-[100vh] drop-shadow-xl' alt=''/>
         <div className=' pl-[5%] w-[45%] 3xl:w-[45%] 2xl:w-[45%] xl:w-[45%] lg:w-[50%] md:w-[95%] sm:w-[95%] xs:w-[95%] pt-[5%] ml-[7%] 3xl:ml-[7%] 2xl:ml-[7%] xl:ml-[7%] lg:ml-[7%] md:ml-[2%] sm:ml-[1%] xs:ml-[1%] text-left absolute'>
          <img src={Logo} alt='Shaliha Tourism' className='w-[125px] h-[150px] 3xl:hidden 2xl:hidden xl:hidden lg:hidden md:block sm:block xs:block ' />
           <h3 className='text-7xl font-bold text-gray-50 leading-[125%] drop-shadow-xl 3xl:text-7xl 2xl:text-6xl xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl xs:text-2xl'>Roam the planet, make memories everlasting</h3>
           <p className='text-sky-300 text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-3xl sm:text-3xl xs:text-3xl font-semibold mt-5 3xl:mt-[5%] leading-[125%] drop-shadow-2xl'>Reach. Relax. We've got it all covered.</p>
           <div className='flex  justify-between mt-[12%] items-center 3xl:hidden 2xl:hidden xl:hidden lg:hidden md:flex sm:flex xs:flex'>
            <img src={SendWhite} className='w-[55px] h-[55px]' alt=''/>
            <img src={FlightWhite} className='w-[75px] h-[75px]' alt='' />
            <img src={TicketsWhite} className='w-[60px] h-[60px]' alt=''/>
           </div>
           <div className='absolute top-[100%] 3xl:top-[150%] 2xl:top-[110%] xl:top-[120%] lg:top-[140%] md:top-[110%] sm:top-[110%] xs:top-[110%] flex gap-[5%]'>
              <img src={Mountains} className='w-[320px] h-[350px] 3xl:w-[350px] 3xl:h-[350px] 2xl:w-[350px] 2xl:h-[300px] xl:w-[300px] xl:h-[280px] lg:w-[300px] lg:h-[280px] md:w-[165px] md:h-[175px] sm:w-[100px] sm:h-[175px] xs:w-[85px] xs:h-[175px] drop-shadow-xl' alt='Mountains' />
              <img src={Beach} className='w-[250px] h-[350px] drop-shadow-xl 3xl:w-[320px] 3xl:h-[350px] 2xl:w-[210px] 2xl:h-[300px] xl:w-[200px] xl:h-[280px] lg:w-[200px] lg:h-[280px] md:w-[115px] md:h-[175px] sm:w-[105px] sm:h-[175px] xs:w-[85px] xs:h-[175px]' alt='Beach'/>
              <img src={Building} className='w-[300px] h-[350px] drop-shadow-xl 3xl:w-[370px] 3xl:h-[350px] 2xl:w-[350px] 2xl:h-[300px] xl:w-[240px] xl:h-[280px] lg:w-[300px] lg:h-[280px] md:w-[165px] md:h-[175px] sm:w-[100px] sm:h-[175px] xs:w-[85px] xs:h-[175px]' alt='City' />
           </div>
         </div>
         <div className='flex flex-col 3xl:flex 2xl:flex xl:flex lg:flex md:hidden sm:hidden xs:hidden justify-around items-end 3xl:justify-around 2xl:justify-around xl:justify-around lg:justify-start 3xl:mt-0 2xl:mt-0 xl:mt-0 lg:mt-[5%] 3xl:gap-0 2xl:gap-0 xl:gap-0 lg:gap-[9%] 3xl:ml-0 2xl:ml-0 xl:ml-0 lg:ml-[2%]'>
         <img src={Send} className='w-[80px] h-[80px] 3xl:w-[80px] 3xl:h-[80px] 2xl:w-[70px] 2xl:h-[70px] xl:w-[60px] xl:h-[60px] lg:w-[50px] lg:h-[50px]' alt='' />
         <img src={Flight} className='w-[100px] h-[100px] 3xl:w-[100px] 3xl:h-[100px] 2xl:w-[90px] 2xl:h-[90px] xl:w-[75px] xl:h-[75px] lg:w-[70px] lg:h-[70px]' alt=''/>
         <img src={Tickets} className='w-[70px] h-[70px] 3xl:w-[70px] 3xl:h-[70px] 2xl:w-[70px] 2xl:h-[70px] xl:w-[70px] xl:h-[70px] lg:w-[50px] lg:h-[50px] 3xl:ml-0 2xl:mr-[-125%] xl:mr-[-270%] lg:ml-0 ' alt=''/>
         </div>
         <div className='relative w-[40%] 3xl:w-[40%] 2xl:w-[40%] xl:w-[40%] lg:w-[60%] 3xl:block 2xl:block xl:block lg:block md:hidden sm:hidden xs:hidden'>
            <div className='w-[200px] 3xl:w-[200px] 2xl:w-[200px] xl:w-[150px] bg-[#0077ea] h-[300px] absolute top-0 3xl:top-0 2xl:top-0 xl:top-[-15%] lg:top-[-22%] opacity-60 ml-[23%] 3xl:ml-[23%] 2xl:ml-[23%] xl:ml-[25%] lg:ml-[32%] rounded-bl-[50px] rotate-[18deg] z-0'></div>
            <img src={Traveller} className='w-[900px] 3xl:w-[900px] 2xl:w-[900px] xl:w-[900px] lg:w-[1200px]  mt-[-3.3%] 3xl:mt-[-3.3%] 2xl:mt-[-3.3%] xl:mt-[-3.3%] lg:mt-[1%] ml-[28%] rounded-bl-[120px] rotate-[10deg] z-0' alt=''/>
            <img src={Triangle} className='3xl:block 2xl:block xl:block  lg:block  md:hidden sm:hidden xs:hidden absolute w-[420px] 3xl:w-[420px] 2xl:w-[280px] xl:w-[210px] lg:w-[120px] right-[-10%] 3xl:right-[2%] lg:right-[-5%] opacity-50 mt-[-40%] 3xl:mt-[-30%] 2xl:mt-[-35%] lg:mt-[-30%] rotate-[310deg] z-10' alt=''/>
            <img src={Blue} className='3xl:block 2xl:block xl:block  lg:block  md:hidden sm:hidden xs:hidden w-[100px] 3xl:w-[100px] 2xl:w-[100px] xl:w-[70px] lg:w-[45px] opacity-70 absolute right-[60%] 3xl:right-[60%] 2xl:right-[60%] xl:right-[50%] mt-[10%] 3xl:mt-[10%] 2xl:mt-[10%] xl:mt-[15%] rotate-[100deg]' alt='' />
            <img src={Blue} className='3xl:block 2xl:block xl:block  lg:block  md:hidden sm:hidden xs:hidden w-[50px] 3xl:w-[50px] 2xl:w-[50px] xl:w-[35px] lg:w-[25px] opacity-70 absolute right-[30%] 3xl:right-[30%] 2xl:right-[30%] xl:right-[20%] mt-[33%] 3xl:mt-[33%] 2xl:mt-[33%] xl:mt-[47%] rotate-[100deg]' alt='' />
         </div>
      </div>
    );
  }
  
  export default BlogBanner;