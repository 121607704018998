import Beach from './../../assets/flight_page/beach.png'
import Building from './../../assets/flight_page/buildings.png'
import Hill from './../../assets/flight_page/field.png'
import Flight from './../../assets/flight_page/globe.png';
import Travel from './../../assets/flight_page/travel-and-tourism.png';
import Cloud from './../../assets/flight_page/cloud.png';
import SideSocialMedia from '../SocialMedia/SocialMediaSide';
import Calendar from './../../assets/flight_page/calender.svg';
import Arrows from './../../assets/flight_page/transfer.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { useState } from 'react';

import Abudhabi from '../../assets/home-page/countries/abudhabi.jpg';
import Georgia from '../../assets/home-page/countries/georgia.jpg';
import Thailand from '../../assets/home-page/countries/thailand.jpg';
import Bali from '../../assets/home-page/countries/bali.jpg';
import Saudi from '../../assets/home-page/countries/saudi-arabia.jpg';
import Spain from '../../assets/home-page/countries/spain.jpg'
import TopHeader from './top_header';
function FlightPage() {
  const [startDate, setStartDate] = useState(new Date());

  const handleStartDateChange = (date) => {
    setStartDate(date);
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
  };
  const phoneNumber = '+971544544971';
  // Handle form submission
  const handleSearch = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
     // Construct the WhatsApp link
     const whatsappLink = `https://wa.me/${phoneNumber}`;
    
     // Open the WhatsApp link in a new tab
     window.open(whatsappLink, '_blank');
  };
  return (
    <div className="h-[100%] poppins dark:text-[#000000] pb-[5%]">
       <Helmet>
        <title>Book the Best and Cheapest Flights | Shaliha Tourism</title>
        <meta
          name="description"
          content="Discover and book the best and cheapest flights with Shaliha Tourism. Enjoy a seamless booking experience with a 100% reservation guarantee. Explore a wide range of destinations and secure your journey today."
        />
      </Helmet>
      <TopHeader/>
      <SideSocialMedia />
      <h1 className='mt-[4%] text-[#3D85C6] font-semibold text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-lg xs:text-lg leading-[200%]'>From Dreams to Skies:<br/> Your Flight, Your Dream Come True!</h1>
      <div className='mt-[3%]'><a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer" className='text-xl 3xl:text-xl 2xl:text-lg xl:text-lg lg:text-sm md:text-sm sm:text-xs xs:text-xs font-semibold p-5 3xl:p-5 2xl:p-5 xl:p-4 lg:p-4 md:p-3 sm:p-3 xs:p-3 w-[100%] rounded-full bg-[#3D85C6] text-white'>Fly with Us</a></div>
      <div className='flex justify-around items-start '>
        <img alt="Hills " src={Hill} className='w-[300px] 3xl:w-[300px] 2xl:w-[230px] xl:w-[175px] lg:w-[145px] md:w-[70px] sm:w-[50px] xs:w-[50px] mt-[-5%]'/>
        <img alt="Building " src={Building} className='w-[300px] 3xl:w-[300px] 2xl:w-[230px] xl:w-[175px] lg:w-[145px] md:w-[73px] sm:w-[50px] xs:w-[50px] mt-[2%]'/>
        <img alt="Beach " src={Beach} className='w-[300px] 3xl:w-[300px] 2xl:w-[230px] xl:w-[175px] lg:w-[145px] md:w-[70px] sm:w-[50px] xs:w-[50px] mt-[6%]'/>
        <img alt="Flight " src={Flight} className='w-[320px] 3xl:w-[320px] 2xl:w-[250px] xl:w-[195px] lg:w-[150px] md:w-[75px] sm:w-[55px] xs:w-[50px] mt-[2%]'/>
        <img alt="Travel " src={Travel} className='w-[300px] 3xl:w-[300px] 2xl:w-[230px] xl:w-[175px] lg:w-[145px] md:w-[70px] sm:w-[50px] xs:w-[50px] mt-[-5%]'/>
      </div>
      <div className='bg-[#3D85C6] text-5xl text-white mt-[5%] '>
        <div className='relative z-0 flex justify-between self-center'>
      <img alt=" " src={Cloud} className='w-[200px] 3xl:w-[200px] 2xl:w-[150px] xl:w-[120px] lg:w-[100px] md:w-[80px] sm:w-[70px] xs:w-[60px] opacity-85'/>
      <img alt=" " src={Cloud} className='w-[200px] 3xl:w-[200px] 2xl:w-[150px] xl:w-[120px] lg:w-[100px] md:w-[80px] sm:w-[70px] xs:w-[60px] opacity-85'/>
      </div>
      <div className='flex justify-around items-center 3xl:justify-around 2xl:justify-around xl:justify-center xl:items-center px-5 3xl:flex-row 2xl:flex-row xl:flex-col lg:flex-col md:flex-col sm:flex-col xs:flex-col'>
      <div className='poppins text-2xl 3xl:text-2xl 2xl:text-xl xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm w-[40%] 3xl:w-[40%] 2xl:w-[43.5%] xl:w-[80%] lg:w-[80%] md:w-[90%] sm:w-[100%] xs:w-[100%] z-10 leading-[200%] 3xl:leading-[200%] 2xl:leading-[200%] xl:leading-[200%] lg:leading-[200%] md:leading-[200%] sm:leading-[200%] xs:leading-[200%] px-[5%]'>
        <p>Picture yourself at the heart of a bustling airport, a gateway to the world, where the hum of anticipation and the promise of adventure fill the air. From the moment you step into the terminal, a symphony of efficiency and innovation guides you through a well-choreographed dance of check-ins, security checks, and boarding procedures.</p>
      </div>
      <img alt=" " src={Cloud} className='relative z-0 w-[300px] 3xl:w-[300px] 2xl:w-[200px] xl:w-[170px] lg:w-[150px] md:w-[100px] sm:w-[100px] xs:w-[100px] opacity-85' />
      <div className='bg-white w-[30%] 3xl:w-[30%] 2xl:w-[40%] xl:w-[80%] lg:w-[80%] md:w-[97%] sm:w-[100%] xs:w-[100%] opacity-90 rounded-xl p-5'>
        <form className='mt-[2%]' onSubmit={handleSearch}>
          <input type="text" placeholder="From (Location)" className="dark:bg-white input input-bordered border-[#3D85C6] text-[#3D85C6] w-full max-w-xs" />
        <div className='flex items-center'>
        <img alt=" " src={Arrows} className='w-[40px] mt-3 3xl:w-[40px] 2xl:w-[30px] xl:w-[30px] lg:w-[30px] md:w-[30px] sm:w-[25px] xs:w-[25px] mx-auto  rotate-90'/>
        </div>
          <input type="text" placeholder="To (Destination)" className="dark:bg-white input input-bordered border-[#3D85C6] text-[#3D85C6] w-full max-w-xs" />
          <div className='mt-[5%] mx-auto dark:bg-white input input-bordered border-[#3D85C6] text-[#3D85C6] w-full max-w-xs ' id="date-picker-container">
            <label className='text-sm flex gap-5 justify-between pt-2 '>Travel Date<img alt=" " src={Calendar} className='w-[20px] 3xl:w-[20px] 2xl:w-[20px] xl:w-[20px] lg:w-[20px] md:w-[15px] sm:w-[15px] xs:w-[15px]'/></label>
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="EEE MMM d, yyyy"
                  className="w-[100%] dark:bg-white outline-none text-lg 3xl:text-lg 2xl:text-lg xl:text-md lg:text-sm md:text-sm sm:text-sm xs:text-sm poppins z-10 "
                />
              </div>
        <button type='submit' className='bg-[#3D85C6] text-xl 3xl:text-xl 2xl:text-lg xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-sm p-3 mt-[5%] rounded-xl w-[40%] 3xl:w-[40%] 2xl:w-[40%] xl:w-[40%] lg:w-[40%] md:w-[100%] sm:w-[100%] xs:w-[100%] '>Search Flights</button>
      </form>
      </div>
      </div>
      <div className='relative z-0 flex justify-between  self-center overflow-hidden'>
      <img alt=" " src={Cloud} className='w-[300px] 3xl:w-[300px] 2xl:w-[200px] xl:w-[190px] lg:w-[170px] md:w-[140px] sm:w-[130px] xs:w-[120px]  opacity-85 mb-[-5%] 3xl:mb-[-5%] 2xl:mb-[-5%] xl:mb-[-5%] lg:mb-[-8%] md:mb-[-12%] sm:mb-[-12%] xs:mb-[-13%]'/>
      <img alt=" " src={Cloud} className='w-[300px] 3xl:w-[300px] 2xl:w-[200px] xl:w-[190px] lg:w-[170px] md:w-[140px] sm:w-[130px] xs:w-[120px] opacity-85 mb-[-5%] 3xl:mb-[-5%] 2xl:mb-[-5%] xl:mb-[-5%] lg:mb-[-8%] md:mb-[-12%] sm:mb-[-12%] xs:mb-[-13%]'/>
      </div>
      <div className=' p-[5%] 3xl:pb-[5%] 2xl:pb-[5%] xl:pb-[5%] lg:pb-[5%] md:pb-[10%] sm:pb-[10%] xs:pb-[10%]'>
        <h2 className='text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl sm:text-2xl xs:text-xl font-semibold text-left'>Trending Tours</h2>
          <div className='grid grid-cols-5 3xl:grid-cols-5 2xl:grid-cols-5 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1  gap-[3%]  mt-[5%]'>
            <button onClick={handleSearch} className='relative drop-shadow-lg overflow-hidden rounded-xl'>
            <img alt=" " src={Abudhabi} className='w-[500px] h-[200px] rounded-xl  hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125  hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125' />
            <p className='text-xl text-white absolute top-[75%]  flex left-[33%]'>Abudhabi</p>
            </button>
            <button onClick={handleSearch} className='relative drop-shadow-lg overflow-hidden rounded-xl'>
            <img alt=" " src={Georgia} className='w-[500px] h-[200px] rounded-xl  hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125  hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125' />
            <p className='text-xl text-white absolute top-[75%] flex left-[38%] '>Georgia</p>
            </button>
            <button onClick={handleSearch} className='relative drop-shadow-lg overflow-hidden rounded-xl'>
            <img alt=" " src={Thailand} className='w-[500px] h-[200px] rounded-xl  hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125' />
            <p className='text-xl text-white absolute top-[75%] flex left-[33%]'>Thailand</p>
            </button>
            <button onClick={handleSearch} className='relative drop-shadow-lg overflow-hidden rounded-xl'>
            <img alt=" " src={Bali} className='w-[500px] h-[200px] rounded-xl  hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125' />
            <p className='text-xl text-white absolute top-[75%] flex left-[33%]'>Indonesia</p>
            </button>
            <button onClick={handleSearch} className='relative drop-shadow-lg overflow-hidden rounded-xl'>
            <img alt=" " src={Saudi} className='w-[500px] h-[200px] rounded-xl  hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125' />
            <p className='text-xl text-white absolute top-[75%] flex left-[30%]'>Saudi Arabia</p>
            </button>
            <button onClick={handleSearch} className='relative drop-shadow-lg 3xl:hidden 2xl:hidden xl:block lg:block md:block sm:block xs:block overflow-hidden rounded-xl'>
            <img alt=" " src={Spain} className='w-[500px] h-[200px] rounded-xl  hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125' />
            <p className='text-xl text-white absolute top-[75%] flex left-[40%]'>Spain</p>
            </button>
            </div>
        </div>
      </div>
    </div>
    
  );
}

export default FlightPage;