import iphone from './../../assets/about_us/apple-iphone-14-max-2022-medium.png';
import AboutUs from './../../assets/about_us/santorini-4044972_1280.jpg';
import Leaf from './../../assets/about_us/leaf (1).png'
import Flight from './../../assets/about_us/flydubai.jpg'
import Visa from './../../assets/about_us/pexels-element-digital-1051075.jpg'
import Holiday from './../../assets/about_us/pexels-photo-5226950.jpeg'
import Hotel from './../../assets/about_us/pexels-pixabay-221457.jpg'
import DubaiMuseum from './../../assets/about_us/dubai-museum.jpg'
import './../../CssComponent/HomePageStyle.css';
import SideSocialMedia from '../SocialMedia/SocialMediaSide';
import Lottie from 'lottie-react';
import VacationLottie from '../../assets/lottiefiles/zipline.json';
import Signal from '../../assets/about_us/mobile-signal-icon.png';
import Umbrella from '../../assets/lottiefiles/girl-umbrella.json';
import Battery from '../../assets/about_us/battery.png';
import { Helmet } from 'react-helmet';
import Logo from '../../assets/logo-name.jpg'
import TopHeader from './top_header';
function About() {
  const phoneNumber = '+971544544971';
  
    return (
      <div className="bg-white dark:text-black pb-[5%]">
        <Helmet>
        <title>About Us | Shaliha Tourism</title>
        <meta
          name="description"
          content="Discover the story of Shaliha Tourism, a leading service provider in the UAE. We specialize in offering a range of services including flight booking, visa application and extension, tour packages, holidays, and hotels and resorts booking. Explore the beauty of the UAE with our inside and outside tours."
        />
      </Helmet>
      <TopHeader/>
        <SideSocialMedia/>
        <div className='flex  items-center justify-between px-[3%] 3xl:px-[5%] 2xl:px-[3%] xl:px-[5%] lg:px-[5%] md:px-[5%] sm:px-[5%] xs:px-[5%] z-0'>
          
          <div className='text-[#3D85C6]'>
          <Lottie animationData={Umbrella} className='w-[30%] mx-auto' />
        <p className=' text-7xl 3xl:text-7xl 2xl:text-6xl xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl xs:text-xl  font-bold poppins'>Crafting Your Vacation Across Globe</p>
        <p className='mt-[5%] text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-lg lg:text-lg sm:text-sm xs:text-xs italic '>One Stop Destination For All Your Needs</p>
        </div>
        <div className='relative block justify-center text-white p-[7%] bg-white 3xl:block 2xl:block xl:block lg:block md:hidden sm:hidden xs:hidden '>
        <img alt=" " src={iphone} className='w-[350px] 3xl:w-[350px] 2xl:w-[350px] xl:w-[350px] lg:w-[350px] overflow-hidden relative mx-auto' />
        <div className='absolute text-black  top-0 overflow-hidden h-[600px] mt-[140px] 5xl:mt-[140px] 4xl:mt-[160px] 3xl:mt-[200px] 2xl:mt-[125px] xl:mt-[88px] lg:mt-[73px] w-[380px] 5xl:w-[273px] 4xl:w-[312.5px] 3xl:w-[312px] 2xl:w-[235px] xl:w-[198px] lg:w-[161.5px] mx-[18px] 5xl:mx-[18px] 4xl:mx-[19.5px] 3xl:mx-[20px] 2xl:mx-[19.5px] xl:mx-[12.5px] lg:mx-[10px] text-[#639dd1] rounded-[20px]'>
          <div className='flex items-center justify-between px-5 3xl:px-5 2xl:px-5 xl:px-5 lg:px-2'>
            <p>10.31</p>
            <div className='flex items-center '>
              <img alt=" " src={Signal} className='w-[15px] '  />
              <img alt=" " src={Battery} className='w-[30px] '  />
            </div>
          </div>
          <Lottie animationData={VacationLottie} className='' />
          <p className='bg-[#3D85C6] px-5  font-semibold text-lg 3xl:text-2xl 2xl:text-lg xl:text-sm lg:text-xs leading-[200%] text-white py-2 drop-shadow-lg'>Your journey, our commitment – the trusted partner making your trip a breeze!</p>
          <img alt=" " src={Logo} className='px-5'  />
          {/* <img alt=" " src={tour} className='h-full w-[100%]' /> */}
     
        </div>
        </div>
        {/* <p className=' top-[30%] right-[10%] w-[45%] 3xl:w-[40%] 3xl:top-[40%] 2xl:w-[45%] 2xl:top-[30%] xl:w-[45%] xl:top-[30%] lg:top-[20%] md:top-[15%] sm:top-[8%] xs:top-[8%] text-3xl 3xl:text-4xl 2xl:text-2xl xl:text-xl lg:text-lg md:text-sm sm:text-sm xs:text-xs poppins text-[#ffffff] poppins leading-[200%] 3xl:leading-[200%] 2xl:leading-[200%] xl:leading-[200%] lg:leading-[175%] md:leading-[150%] sm:leading-[150%] xs:leading-[150%]'>Committed to making your travel dreams a reality, Shaliha Tourism offers seamless ticket booking, personalized packages, and unwavering support. Trust us for a confident journey.</p> */}
        </div>
        <div className='bg-[#e5f9fe]  mt-[10%] flex justify-between 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-col md:flex-col sm:flex-col xs:flex-col'>
          <div className='relative p-[10%] w-[40%] 3xl:w-[70%] 2xl:w-[70%] xl:w-[50%] lg:w-[100%] sm:w-[100%] xs:w-[100%]  overflow-hidden ' >
            <div className='relative z-50 2xl:left-[5%] xl:left-[10%] text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm md:text-md sm:text-md xs:text-sm poppins w-[100%] 3xl:w-[90%] 2xl:w-[100%] xl:w-[100%] sm:w-[100%] xs:w-[100%] leading-[200%] 3xl:leading-[200%]'>
          <h3 className='text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-lg lg:text-lg md:text-lg sm:text-lg xs:text-lg font-semibold'>About Us</h3>
          <p className='mt-10 3xl:mt-10 2xl:mt-10 xl:mt-5'>Welcome to Shaliha Tourism, where your journey becomes our commitment. With a passion for seamless travel, we specialize in effortless ticket booking, personalized holiday packages, and unwavering support.</p>
          <p className='mt-5 block 3xl:block 2xl:block xl:block lg:block md:hidden sm:hidden xs:hidden'>Guided by a proven track record, our team is dedicated to transforming your travel aspirations into memorable experiences. From intricate visa processes to curated itineraries, we strive for excellence in every detail.</p>
          <p className='mt-5 block 3xl:block 2xl:block xl:block lg:block md:hidden sm:hidden xs:hidden'>At Shaliha Tourism, our commitment extends beyond destinations; it's about creating confidence in your travel. Trust us for transparency, reliability, and personalized service that ensures your journey is not just a trip but a lifetime memory.</p>
          </div>
            <img alt=" " src={Leaf} className='absolute z-0 top-[30%]  rotate-12 w-[70%] 3xl:w-[50%] 2xl:w-[70%] xl:w-[90%] lg:w-[50%] 2xl:top-[30%] lg:top-[20%]' />
            
          </div>
          <img alt=" " src={AboutUs} className='w-[800px] 3xl:w-[1000px] 2xl:w-[650px] xl:w-[500px] lg:[500px] drop-shadow-lg' />
        </div>
        <div className='bg-[#e5f9fe]  mt-[10%] flex justify-between 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-col-reverse md:flex-col-reverse sm:flex-col-reverse xs:flex-col-reverse'>
        <img alt=" " src={Flight} className='w-[800px] 3xl:w-[1100px] 2xl:w-[650px] xl:w-[500px] lg:[500px] drop-shadow-lg' />
          <div className='relative p-[5%] 3xl:p-[5%] 2xl:p-[5%] xl:p-[5%] lg:p-[10%] md:p-[15%] sm:p-[15%] xs:p-[15%]  w-[40%] 3xl:w-[70%] 2xl:w-[70%] xl:w-[50%] lg:w-[100%] sm:w-[100%] xs:w-[100%]  overflow-hidden ' >
            <div className='relative z-10 text-left 2xl:left-[5%] xl:left-[10%] text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm sm:text-xs xs:text-xs poppins w-[100%] 3xl:w-[90%] 2xl:w-[100%] xl:w-[100%] sm:w-[100%] xs:w-[100%] leading-[200%] 3xl:leading-[200%]'>
          <h3 className='text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-lg lg:text-lg sm:text-sm xs:text-sm font-semibold'>Flight Ticket</h3>
          <p className='mt-5 block 3xl:block 2xl:block xl:hidden lg:hidden md:hidden sm:hidden xs:hidden'>we simplify your global travel experience with our comprehensive flight ticket booking service. Whether you're jetting off for business or leisure, trust us to secure your flights to any destination worldwide. Your journey begins with seamless bookings, catering to all countries and ensuring your travel dreams take flight effortlessly.</p>
          <p className='mt-5 3xl:mt-5 2xl:mt-5 xl:mt-5'> Your flight is just a click away. With a user-friendly platform, we simplify the booking process, offering a range of options to suit your travel needs. Fly with ease as we secure your tickets for hassle-free journeys to any destination.</p>
          <h3 className='mt-5 text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-lg lg:text-lg sm:text-sm xs:text-sm font-semibold'>A2A Booking</h3>
          <p className='mt-5 block 3xl:block 2xl:block xl:hidden lg:hidden md:hidden sm:hidden xs:hidden'>our A-to-A booking service ensures a smooth journey from Arrival to Departure. With a commitment to simplicity and convenience, we handle all aspects of your travel logistics, from securing flight tickets to accommodations, creating a seamless experience from start to finish.</p>
          <p className='mt-5 3xl:mt-5 2xl:mt-5 xl:mt-5'>Experience A-to-A convenience for your flights. From Arrival to Departure, we handle every detail, ensuring a seamless journey. Trust us to book your flights and make your travel experience effortless from start to finish.</p>
          <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer" className='btn border-2 rounded-none border-black bg-transparent text-black text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm sm:text-xs xs:text-xs mt-5 whitespace-nowrap w-[30%] 3xl:w-[30%] 2xl:w-[30%] xl:w-[30%] lg:w-[30%] md:w-[40%] sm:w-[50%] xs:w-[50%] px-5 py-2 hover:bg-black hover:text-white'>BOOK NOW</a>
           </div>
           <img alt=" " src={Leaf} className='absolute z-0 top-[30%]  rotate-12 w-[70%] 3xl:w-[50%] 2xl:w-[70%] xl:w-[90%] lg:w-[50%] 2xl:top-[30%] lg:top-[20%]' />
            
          </div>
          
        </div>
        <div className='bg-[#e5f9fe]  mt-[10%] flex justify-between 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-col md:flex-col sm:flex-col xs:flex-col'>
        
          <div className='relative p-[5%] 3xl:p-[5%] 2xl:p-[5%] xl:p-[5%] lg:p-[10%] md:p-[15%] sm:p-[15%] xs:p-[15%]  w-[40%] 3xl:w-[70%] 2xl:w-[70%] xl:w-[50%] lg:w-[100%] sm:w-[100%] xs:w-[100%]  overflow-hidden ' >
            <div className='relative z-10 text-left 2xl:left-[5%] xl:left-[10%] text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm sm:text-xs xs:text-xs poppins w-[100%] 3xl:w-[90%] 2xl:w-[100%] xl:w-[100%] sm:w-[100%] xs:w-[100%] leading-[200%] 3xl:leading-[200%]'>
          <h1 className='text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-lg lg:text-lg sm:text-sm xs:text-sm font-semibold'>Visit Visa</h1>
          <p className='mt-5 3xl:mt-5 2xl:mt-5 xl:mt-5'>Explore the world with Shaliha Tourism's visit visa services, covering destinations worldwide. Our streamlined process and expert guidance make obtaining a visit visa hassle-free, allowing you to embark on your global adventures with ease. Trust us to facilitate your visa needs for a memorable journey across all countries.</p>
           <h1 className='mt-5 text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-lg lg:text-lg sm:text-sm xs:text-sm font-semibold'>Visa Extension</h1>
          <p className='mt-53xl:mt-5 2xl:mt-5 xl:mt-5'> we understand that plans may change. Our visa extension services offer flexibility for your travels. Whether you need more time to explore or unexpected circumstances arise, trust us to navigate the extension process, ensuring your journey continues smoothly.</p>
          <h1 className='mt-5 text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-lg lg:text-lg sm:text-sm xs:text-sm font-semibold'>Visa Hub: Renewal & New</h1>
          <p className='mt-53xl:mt-5 2xl:mt-5 xl:mt-5'>we facilitate a range of visa services to suit diverse needs. From residence and employment visas to freelancer and even golden visas, our expert team ensures a seamless process for all types of visa applications. Trust us to navigate the complexities, providing you with the key to unlock opportunities around the world.</p>
          <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer" className='btn border-2 rounded-none border-black bg-transparent text-black text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm sm:text-xs xs:text-xs mt-5 whitespace-nowrap w-[30%] 3xl:w-[30%] 2xl:w-[30%] xl:w-[30%] lg:w-[30%] md:w-[40%] sm:w-[50%] xs:w-[50%] px-5 py-2 hover:bg-black hover:text-white'>BOOK NOW</a>
           </div>
           <img alt=" " src={Leaf} className='absolute z-0 top-[30%]  rotate-12 w-[70%] 3xl:w-[50%] 2xl:w-[70%] xl:w-[90%] lg:w-[50%] 2xl:top-[30%] lg:top-[20%] ' />
            
          </div>
          <img alt=" " src={Visa} className='w-[800px] 3xl:w-[1100px] 2xl:w-[650px] xl:w-[500px] lg:[500px] drop-shadow-lg ' />
        </div>
        <div className='bg-[#e5f9fe]  mt-[10%] flex justify-between 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-col-reverse md:flex-col-reverse sm:flex-col-reverse xs:flex-col-reverse'>
        <img alt=" " src={Holiday} className='w-[800px] 3xl:w-[1100px] 2xl:w-[650px] xl:w-[500px] lg:[500px] drop-shadow-lg' />
          <div className='relative p-[5%] 3xl:p-[5%] 2xl:p-[5%] xl:p-[5%] lg:p-[10%] md:p-[15%] sm:p-[15%] xs:p-[15%]  w-[40%] 3xl:w-[70%] 2xl:w-[70%] xl:w-[50%] lg:w-[100%] sm:w-[100%] xs:w-[100%]  overflow-hidden ' >
            <div className='relative z-10 text-left 2xl:left-[5%] xl:left-[10%] text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm sm:text-xs xs:text-xs poppins w-[100%] 3xl:w-[90%] 2xl:w-[100%] xl:w-[100%] sm:w-[100%] xs:w-[100%] leading-[200%] 3xl:leading-[200%]'>
          <h3 className='text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-lg lg:text-lg sm:text-sm xs:text-sm font-semibold'>Holiday Packages</h3>
          <p className='mt-5 block 3xl:block 2xl:block xl:hidden lg:hidden md:hidden sm:hidden xs:hidden'>Escape with Shaliha Tourism: Your Journey, Your Way</p>
          <p className='mt-5 3xl:mt-5 2xl:mt-5 xl:mt-5'> Embark on extraordinary adventures with our curated holiday packages. From serene beach retreats to thrilling cultural explorations, Shaliha Tourism crafts bespoke experiences tailored to your desires.</p>
          <p className='mt-5 block 3xl:block 2xl:block xl:hidden lg:hidden md:hidden sm:hidden xs:hidden'>Discover a world of possibilities as our diverse packages cater to all preferences. Whether you seek relaxation or adventure, our team ensures every detail is meticulously planned for an unforgettable holiday.</p>
          <p className='mt-5 3xl:mt-5 2xl:mt-5 xl:mt-5'>Let Shaliha Tourism be your guide, creating memories that last a lifetime. Explore our holiday packages and let your next journey begin with us.</p>
          <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer" className='btn border-2 rounded-none border-black bg-transparent text-black text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm sm:text-xs xs:text-xs mt-5 whitespace-nowrap w-[30%] 3xl:w-[30%] 2xl:w-[30%] xl:w-[30%] lg:w-[30%] md:w-[40%] sm:w-[50%] xs:w-[50%] px-5 py-2 hover:bg-black hover:text-white'>BOOK NOW</a>
           </div>
           <img alt=" " src={Leaf} className='absolute z-0 top-[30%]  rotate-12 w-[70%] 3xl:w-[40%] 2xl:w-[60%] xl:w-[65%] lg:w-[50%] 2xl:top-[30%] lg:top-[20%]' />
            
          </div>
          
        </div>
        <div className='bg-[#e5f9fe]  mt-[10%] flex justify-between 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-col md:flex-col sm:flex-col xs:flex-col'>
          <div className='relative p-[5%] 3xl:p-[5%] 2xl:p-[5%] xl:p-[5%] lg:p-[10%] md:p-[15%] sm:p-[15%] xs:p-[15%] w-[40%] 3xl:w-[70%] 2xl:w-[70%] xl:w-[50%] lg:w-[100%] sm:w-[100%] xs:w-[100%]  overflow-hidden ' >
            <div className='text-left relative z-50 2xl:left-[5%] xl:left-[10%] text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm md:text-md sm:text-md xs:text-sm poppins w-[100%] 3xl:w-[90%] 2xl:w-[100%] xl:w-[100%] sm:w-[100%] xs:w-[100%] leading-[200%] 3xl:leading-[200%]'>
            <h3 className='text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-lg lg:text-lg sm:text-sm xs:text-sm font-semibold'>Hotels</h3>
          <p className='mt-5 block 3xl:block 2xl:block xl:hidden lg:hidden md:hidden sm:hidden xs:hidden'>Discover a world of exceptional accommodations through our hotel services. Shaliha Tourism partners with top-tier hotels worldwide to offer you a range of choices that cater to your preferences and ensure a comfortable stay.</p>
          <p className='mt-5 3xl:mt-5 2xl:mt-5 xl:mt-5'>From luxurious resorts to budget-friendly options, our extensive selection guarantees a perfect match for every traveler. Experience the pinnacle of hospitality with Shaliha Tourism, where your comfort is our priority, and every stay becomes a cherished part of your journey across UAE and other Countries.</p>
          <h3 className='mt-5 text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-lg lg:text-lg sm:text-sm xs:text-sm font-semibold'>Resorts</h3>
          <p className='mt-5 block 3xl:block 2xl:block xl:hidden lg:hidden md:hidden sm:hidden xs:hidden'>Escape to luxury and tranquility with our handpicked resort destinations. Shaliha Tourism takes pride in curating exceptional resort experiences, ensuring your getaway is a harmonious blend of comfort, recreation, and natural beauty.</p>
          <p className='mt-5 3xl:mt-5 2xl:mt-5 xl:mt-5'>Immerse yourself in the lap of luxury, where our carefully selected resorts offer a retreat from the ordinary. Explore our resort options, each promising an indulgent escape, and let Shaliha Tourism elevate your holiday to a realm of unparalleled relaxation and serenity across UAE and other Countries.</p> 
          <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer" className='btn border-2 rounded-none border-black bg-transparent text-black text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm sm:text-xs xs:text-xs mt-5 whitespace-nowrap w-[30%] 3xl:w-[30%] 2xl:w-[30%] xl:w-[30%] lg:w-[30%] md:w-[40%] sm:w-[50%] xs:w-[50%] px-5 py-2 hover:bg-black hover:text-white'>BOOK NOW</a>
          </div>
            <img alt=" " src={Leaf} className='absolute z-0 top-[30%]  rotate-12 w-[70%] 3xl:w-[50%] 2xl:w-[70%] xl:w-[90%] lg:w-[50%] 2xl:top-[30%] lg:top-[20%]' />
             </div>
          <img alt=" " src={Hotel} className='w-[800px] 3xl:w-[1000px] 2xl:w-[700px] xl:w-[500px] lg:[500px] drop-shadow-lg' />
        </div>
        <div className='bg-[#e5f9fe]  mt-[10%] flex justify-between 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-col-reverse md:flex-col-reverse sm:flex-col-reverse xs:flex-col-reverse'>
        <img alt=" " src={DubaiMuseum} className='w-[800px] 3xl:w-[1100px] 2xl:w-[650px] xl:w-[500px] lg:[500px] drop-shadow-lg' />
          <div className='relative p-[5%] 3xl:p-[5%] 2xl:p-[5%] xl:p-[5%] lg:p-[10%] md:p-[15%] sm:p-[15%] xs:p-[15%]  w-[40%] 3xl:w-[70%] 2xl:w-[70%] xl:w-[50%] lg:w-[100%] sm:w-[100%] xs:w-[100%]  overflow-hidden ' >
            <div className='relative z-10 text-left 2xl:left-[5%] xl:left-[10%] text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm sm:text-xs xs:text-xs poppins w-[100%] 3xl:w-[90%] 2xl:w-[100%] xl:w-[100%] sm:w-[100%] xs:w-[100%] leading-[200%] 3xl:leading-[200%]'>
          <h3 className='text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-lg lg:text-lg sm:text-sm xs:text-sm font-semibold'>Inbound and Outbound Tour UAE</h3>
          <p className='mt-5 block 3xl:block 2xl:block xl:hidden lg:hidden md:hidden sm:hidden xs:hidden'>Discover the UAE: Inbound and Outbound Tours with Shaliha Tourism</p>
          <p className='mt-5 3xl:mt-5 2xl:mt-5 xl:mt-5'>Embark on a journey of exploration with Shaliha Tourism's inbound and outbound tour services in the UAE. Whether you're eager to uncover the rich cultural tapestry of the Emirates or set your sights on international adventures, our expert team ensures a seamless and unforgettable travel experience.</p>
          <p className='mt-5 block 3xl:block 2xl:block xl:hidden lg:hidden md:hidden sm:hidden xs:hidden'>Experience the wonders of the UAE with our carefully crafted inbound tours, showcasing the beauty and diversity of the region. Additionally, trust us to curate outbound tours, extending your horizons beyond borders to explore the world. Shaliha Tourism, your gateway to immersive and memorable travel experiences in and beyond the UAE.</p>
          <a href={`https://wa.me/${phoneNumber}`} target="_blank" rel="noopener noreferrer" className='btn border-2 rounded-none border-black bg-transparent text-black text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm sm:text-xs xs:text-xs mt-5 whitespace-nowrap w-[30%] 3xl:w-[30%] 2xl:w-[30%] xl:w-[30%] lg:w-[30%] md:w-[40%] sm:w-[50%] xs:w-[50%] px-5 py-2 hover:bg-black hover:text-white'>BOOK NOW</a>
           </div>
           <img alt=" " src={Leaf} className='absolute z-0 top-[30%]  rotate-12 w-[70%] 3xl:w-[40%] 2xl:w-[60%] xl:w-[65%] lg:w-[50%] 2xl:top-[30%] lg:top-[20%]' />
            
          </div>
          
        </div>
      </div>
    );
  }
  
  export default About;