import Phonecall from './../../assets/phone-call-white.svg';
import Mail from './../../assets/mail-blue.svg';
import Copyright from './../../assets/copyright.png';
import Map from './../../assets/background-images/shaliha-map.jpg'
import { useNavigate } from 'react-router-dom';
function Footer() {
    const navigate = useNavigate();
    const goToAbout = () => {
        navigate('/about-us');
    };

    const goToFlights = () => {
        navigate('/flights');
    };
    const goToVisa = () => {
        navigate('/visa');
    };

    const goToHotel = () => {
        navigate('/hotel');
    };

    const goToTour = () => {
        navigate('/tour');
    };

    const goToCruise = () => {
        navigate('/cruise');
    };
    return (
        <footer className="bg-[#639dd1] absolute w-[99.1vw]   mb-0 left-0 text-white text-left">

            <div className="p-[4%] 3xl:p-[4%] 2xl:p-[4%] xl:p-[7%] lg:p-[4%] md:p-[12%] sm:p-[13%] xs:p-[15%]">

                <div className='flex gap-5 justify-around 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-col md:flex-col sm:flex-col xs:flex-col 3xl:justify-around 2xl:justify-around xl:justify-around xl:items-start 3xl:text-left 2xl:text-left xl:text-left lg:text-center lg:items-center sm:justify-center xs:justify-center'>
                    <div>
                        <ul className='xl:text-sm 3xl:text-lg 2xl:text-lg xl:text-lg lg:text-md md:text-sm sm:text-xs xs:text-xs'>
                            <h3 className="text-2xl 3xl:text-2xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-xs xs:text-xs font-bold whitespace-nowrap">Quick Links</h3>
                            <li className='mt-[2%] '><a href='#about' onClick={goToAbout}>About us</a></li>
                            <li><a href='#visa' onClick={goToVisa}>Visa</a></li>
                            <li><a href='#flight' onClick={goToFlights}>Ticket Booking</a></li>
                            <li><a href='#hotel' onClick={goToHotel}>Hotel</a></li>
                            <li><a href='#tour' onClick={goToTour}>Holidays</a></li>
                            <li><a href='#cruise' onClick={goToCruise}>Yacht and Cruise</a></li>
                        </ul>
                    </div>
                    <div>
                        <ul className=' 3xl:text-lg 2xl:text-lg xl:text-lg lg:text-md md:text-sm sm:text-xs xs:text-xs'>
                            <h5 className='text-2xl 3xl:text-2xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-sm font-bold'>Explore</h5>
                            <li className='mt-[2%]'>Tour Packages</li>
                            <li>Privacy Policy</li>
                            <li>Offers</li>
                        </ul>
                    </div>
                    <div>
                        <ul className='justify-left items-center leading-10  lg:justify-center 3xl:text-left 2xl:text-left xl:justify-left xl:text-sm 3xl:text-lg 2xl:text-lg xl:text-lg lg:text-md md:text-sm sm:text-xs xs:text-xs'>
                            <h5 className='text-2xl 3xl:text-2xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-sm font-bold'>Contact</h5>
                            <li className='flex flex-col gap-3 items-center 3xl:items-start 2xl:items-start xl:items-start lg:items-center md:items-start sm:items-start xs:items-start justify-left lg:justify-center 3xl:text-left 2xl:text-left xl:justify-left mt-[2%]'>

                                <a className='flex items-center' href="tel:+971 563576667"><span ><img  src={Phonecall} alt='Call' width={25}></img> </span>&nbsp;&nbsp;+971 544544971</a>
                                <a className='flex items-center' href="tel:045299279"><span ><img  src={Phonecall} alt='Call' width={25}></img> </span>&nbsp;&nbsp;045299279</a>
                            </li>
                            <li className='flex items-center mt-2 lg:justify-center  3xl:text-lg 2xl:text-lg xl:text-lg lg:text-md md:text-sm sm:text-sm xs:text-sm'>
                                <span ><img  src={Mail} alt='Call' width={25}></img> </span>&nbsp;&nbsp;
                                <a href="mailto:shalihatourism@gmail.com">shalihatourism@gmail.com</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className=''>
                            <ul className='justify-left '>
                                <h5 className='text-2xl 3xl:text-2xl 2xl:text-xl xl:text-lg lg:text-sm sm:text-sm xs:text-sm xs:text-xs font-bold'>Newsletter</h5>
                                <form className='mt-[5%] flex flex-col justify-left gap-3 lg:justify-center md:justify-center sm:justify-center xs:justify-center '>
                                    <input type="text" placeholder="Enter Email Address Here" className="input input-bordered w-full max-w-xs dark:bg-white" />
                                    <button className="btn text-lg text-zinc-400 w-full max-w-xs dark:bg-white">Subscribe</button>
                                </form>
                            </ul>
                        </div>
                        <div>
                            <h5 className='text-2xl 3xl:text-2xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm smsm:text-sm xs:text-sm font-bold text-left'>Location</h5>
                            <a href='https://www.google.com/maps/place/Shaliha+Tourism/@25.2451184,55.3017578,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f437bf0ae1785:0x53fb0fdba2736358!8m2!3d25.2451136!4d55.3066287!16s%2Fg%2F11rtqkkfmp?authuser=0&entry=ttu' target="_blank" rel="noopener noreferrer"><img alt=" " src={Map} width={300} className='' /></a>
                        </div>
                    </div>
                </div>
            </div>
            <p className='mb-0 flex justify-center p-2'>Copyright &nbsp;<img alt=" " src={Copyright} className='w-[25px] 3xl:w-[25px] 2xl:w-[25px] xl:w-[25px] lg:w-[25px] md:w-[25px] sm:w-[25px] xs:w-[15px]' />&nbsp;2024. All rights reserved. </p>
        </footer>
    );
}

export default Footer;