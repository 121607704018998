
import Search from './../../assets/icons/visa-page-images/search.png';
import Calendar from './../../assets/icons/header-icons/monthly-calendar-svgrepo-com.svg'

import Voco from './../../assets/hotel_page/voco-dubai.jpg';
import ParkHyatt from './../../assets/hotel_page/park-hyatt.jpg';
import CrownPlaza from './../../assets/hotel_page/crown-plaza.jpg';
import Baracuda from './../../assets/hotel_page/baracuda.jpg';
import Raddison from './../../assets/hotel_page/radisson_beach_resort.jpg';
import Hilton from './../../assets/hotel_page/hilton.jpg';
import Address from './../../assets/hotel_page/address-beach-resort.jpg';
import Atlantis from './../../assets/hotel_page/atlantis.jpg';
import FiveJumeirah from './../../assets/hotel_page/5-jumeirah.jpg';
import Paragraph from './../../assets/hotel_page/paragraph.jpg';
import Bali from './../../assets/hotel_page/bali.jpg';
import Azarbaijan from './../../assets/hotel_page/azarbaijan.jpg';
import Hongkong from './../../assets/hotel_page/disney-hotel.jpg';
import Italy from './../../assets/hotel_page/italy.jpg';
import Paris from './../../assets/hotel_page/paris.jpg';
import Turkey from './../../assets/hotel_page/turkey.jpg';
import Thailand from './../../assets/hotel_page/thailand.jpg';
import Singapore from './../../assets/hotel_page/singapore.jpg';
import Small from './../../assets/hotel_page/sm.jpeg';
import { Helmet } from 'react-helmet';
import TopHeader from './top_header';

import Resort from '../../assets/hotel_page/pexels-asad-photo-maldives-1268855.jpg'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import './../../CssComponent/HomePageStyle.css'

import { useState} from 'react';
import SideSocialMedia from '../SocialMedia/SocialMediaSide';
function HotelPage() {
  

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    setEndDate(nextDay);
  };

  // ------------------------------------------------------

  // Handle form submission
  const handleSearch = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    const phoneNumber = '+971544544971';
    const whatsappLink = `https://wa.me/${phoneNumber}`;

    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };
  return (
    <div className="h-[100%] dark:text-[#000000] pb-[5%]">
     <Helmet>
        <title>Book Resorts and Hotels Worldwide | Shaliha Tourism</title>
        <meta
          name="description"
          content="Discover a world of accommodation options with Shaliha Tourism. We offer affordable booking services for resorts and hostels in the UAE and various other countries. Explore our special offers and find the perfect stay for your next adventure."
        />
      </Helmet>
      <TopHeader/>
      <SideSocialMedia />
      <div className='relative'>
        {/* ---------------- BIG SCREEN ------------------ */}
        <img alt=" " src={Resort} className='h-[100vh] w-[100vw] 3xl:block 2xl:block xl:block lg:block md:hidden sm:hidden xs:hidden' />
        {/* ---------------- SMALL SCREEN ------------------ */}
        <img alt=" " src={Small} className='h-[100vh]  3xl:hidden 2xl:hidden xl:hidden lg:hidden md:block sm:block xs:block' />
        <div className='absolute w-[50%] 3xl:w-[40%] 2xl:w-[50%] xl:w-[75%] lg:w-[90%] md:w-[80%] sm:w-[80%] xs:w-[80%] top-[15%] left-[5%] 3xl:left-[5%] 2xl:left-[5%] xl:left-[12%] lg:left-[7%] md:left-[12%] sm:left-[12%] xs:left-[12%] text-white '>
          <h1 className="mt-[5%] font-semibold text-5xl 3xl:text-7xl 2xl:text-4xl xl:text-4xl lg:text-4xl md:text-xl sm:text-lg xs:text-sm">Discover Your Perfect Stay</h1>
          <p className='leading-[200%] 3xl:leading-[150%] text-3xl 3xl:text-4xl 2xl:text-2xl xl:text-xl lg:text-lg md:text-md sm:text-sm xs:text-xs mt-[3%] 3xl:mt-[5%] leading-[150%]'>Discover your perfect stay. Book now for comfort, luxury, and unforgettable moments.</p>
          {/* ---------------- BIG SCREEN ------------------ */}
          <div className='flex justify-center mt-[3%] text-left poppins text-black 3xl:block 2xl:block xl:block lg:block md:hidden sm:hidden xs:hidden'>
            <div className='bg-white  opacity-80 w-[75%] 3xl:w-[100%] 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%]  p-[0.7%] rounded-full  drop-shadow-xl px-[1%] input-container'>
              <form className='flex gap-5 items-center 3xl:gap-2 2xl:gap-2 xl:gap-2 lg:gap-2 md:gap-5 sm:gap-5 xs:gap-5 justify-center text-lg 3xl:text-lg 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs  ' onSubmit={handleSearch}>
                <input type='text' placeholder='Search Country' className='dark:bg-white text-lg poppins 3xl:w-[75%] 2xl:w-[75%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] text-lg 3xl:text-lg 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs border-r-2 ' />
                <div className='input-container 3xl:w-[25%] 2xl:w-[25%] xl:w-[25%] lg:w-[25%] md:w-[25%] sm:w-[25%] xs:w-[25%] text-lg 3xl:text-lg 2xl:text-sm xl:text-sm lg:text-sm md:text-xs sm:text-xs xs:text-xs' id="date-picker-container">
                  <label for="date-from" className='flex gap-3 uppercase poppins items-center'><img alt=" " src={Calendar} width={20} />checkin</label>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat="EEE MMM d, yyyy"
                    className="border rounded-md  py-2 outline-none  poppins z-10 "
                  />
                </div>
                <div className='input-container 3xl:w-[25%] 2xl:w-[25%] xl:w-[25%] lg:w-[25%] md:w-[15%] sm:w-[15%] xs:w-[15%] text-lg 3xl:text-lg 2xl:text-sm xl:text-sm lg:text-sm md:text-xs sm:text-xs xs:text-xs' id="date-picker-container">
                  <label for="date-from" className='flex gap-3 uppercase poppins items-center'><img alt=" " src={Calendar} width={20} />checkout</label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="EEE MMM d, yyyy"
                    className="border rounded-md px-3 py-2 outline-none poppins "
                    minDate={startDate}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    selectsEnd
                    disabled={!startDate}

                  />
                </div>
                <button type='submit' className='' ><img alt=" " src={Search} className='customInput mr-0 3xl:w-[100px] 2xl:w-[100px] xl:w-[100px] lg:w-[120px] ' /></button>
              </form>
            </div>
          </div>
          {/* ---------------- SMALL SCREEN ------------------ */}
          <div className='3xl:hidden 2xl:hidden xl:hidden lg:hidden md:block sm:block xs:block w-[100%] text-black mt-5'>
            <form className=' text-lg 3xl:text-lg 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs  ' onSubmit={handleSearch}>
              <input type='text' placeholder='Search Country' className='input input-bordered w-full w-max-xs rounded-full dark:bg-white text-lg poppins 3xl:w-[75%] 2xl:w-[75%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] text-lg 3xl:text-lg 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs border-r-2 ' />
              <div className='input-container flex items-center justify-evenly gap-5 mt-5 bg-white  input input-bordered w-full w-max-xs rounded-full p-2 text-lg  md:text-sm sm:text-sm xs:text-xs' id="date-picker-container">
                <label for="date-from" className='flex gap-3 uppercase poppins items-center'><img alt=" " src={Calendar} width={20} />checkin</label>
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="EEE MMM d, yyyy"
                  className="border rounded-md  outline-none  poppins z-10 "
                />
              </div>
              <div className='input-container flex items-center justify-evenly gap-5 md:gap-5 sm:gap-5 xs:gap-[10%] mt-5 bg-white  input input-bordered w-full w-max-xs pl-5 rounded-full p-2  md:text-sm sm:text-sm xs:text-xs' id="date-picker-container">
                <label for="date-from" className='flex gap-3 uppercase poppins items-center'><img alt=" " src={Calendar} width={20} />checkout</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="EEE MMM d, yyyy"
                  className="border rounded-md  outline-none poppins "
                  minDate={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  selectsEnd
                  disabled={!startDate}
                  placeholderText='Select Date'

                />
              </div>
              <button type='submit' className='mt-5' ><img alt=" " src={Search} className='customInput mr-0 3xl:w-[100px] 2xl:w-[100px] xl:w-[100px] lg:w-[120px] ' /></button>
            </form>
          </div>
          <p className='3xl:text-xl 2xl:text-lg xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs mt-[3%] text-left'>* Our exclusive resort offers luxury accommodation with amenities that go far beyond average hotels and resorts. </p>
        </div>
      </div>
      {/* ------------------------CAROUSEL------------------------------- */}
      <h1 className='text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-2xl lg:text-xl md:text-lg sm:text-lg xs:text-sm text-left poppins mt-[7%] ml-[5%] font-semibold'>Featured Hotel & Resorts in UAE</h1>
      {/* -------------------------------BIG SCREEN--------------------------- */}
      <div className="carousel w-full mt-[3%] 3xl:flex 2xl:flex xl:flex lg:flex md:hidden sm:hidden xs:hidden ">
        <div id="slide1" className="carousel-item relative w-full mx-[10%] ">
          <div className='flex gap-[5%] justify-between px-[9%]' >
            <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Voco} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Voco, an IGH Hotel</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Sheik Zayed Road, Dubai</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={ParkHyatt} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Park Hyatt</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Al Saadiyat Island, Abu Dhabi</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl'>
              <img alt=" " src={CrownPlaza} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Crown Plaza</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Dubai - Deira - Abu Dhabi</p>
              </div>
            </div>
          </div>

          <div className="px-[4%] absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <a href="#slide3" className="btn btn-circle bg-white drop-shadow-xl">❮</a>
            <a href="#slide2" className="btn btn-circle bg-white drop-shadow-xl">❯</a>
          </div>

        </div>
        <div id="slide2" className="carousel-item relative w-full">
          <div className='flex gap-[5%] justify-between px-[9%]' >
            <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Baracuda} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Barracuda Beach Resort</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Umm Al Quwain</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Hilton} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Hilton</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Palm Jumeirah, Dubai</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl'>
              <img alt=" " src={Raddison} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Raddison Beach Resort</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Palm Jumeirah, Dubai</p>
              </div>
            </div>
          </div>

          <div className="px-[4%] absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <a href="#slide1" className="btn btn-circle bg-white drop-shadow-xl">❮</a>
            <a href="#slide3" className="btn btn-circle bg-white drop-shadow-xl">❯</a>
          </div>
        </div>
        <div id="slide3" className="carousel-item relative w-full">
          <div className='flex gap-[5%] justify-between px-[9%]' >
            <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Address} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Address Beach Resort</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Marina Beach, Dubai</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Atlantis} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Atlantis The Royal</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Palm Jumeirah, Dubai</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl'>
              <img alt=" " src={FiveJumeirah} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Five Jumeirah Village</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Dubai</p>
              </div>
            </div>
          </div>

          <div className="px-[4%] absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <a href="#slide2" className="btn btn-circle bg-white drop-shadow-xl">❮</a>
            <a href="#slide1" className="btn btn-circle bg-white drop-shadow-xl">❯</a>
          </div>
        </div>

      </div>
      {/* -------------------------------SMALL SCREEN--------------------------- */}
      <div className="h-96 carousel carousel-vertical rounded-xl mt-5 drop-shadow-xl 3xl:hidden 2xl:hidden xl:hidden lg:hidden md:block sm:block xs:block">
        <div className="carousel-item h-full ">
        <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Voco} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Voco, an IGH Hotel</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Sheik Zayed Road, Dubai</p>
              </div>
            </div>
        </div>
        <div className="carousel-item h-full">
        <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={ParkHyatt} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Park Hyatt</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Al Saadiyat Island, Abu Dhabi</p>
              </div>
            </div>
        </div>
        <div className="carousel-item h-full">
        <div className='bg-white rounded-xl drop-shadow-xl'>
              <img alt=" " src={CrownPlaza} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Crown Plaza</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Dubai - Deira - Abu Dhabi</p>
              </div>
            </div>
        </div>
        <div className="carousel-item h-full">
        <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Baracuda} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Barracuda Beach Resort</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Umm Al Quwain</p>
              </div>
            </div>
        </div>
        <div className="carousel-item h-full">
        <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Hilton} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Hilton</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Palm Jumeirah, Dubai</p>
              </div>
            </div>
        </div>
        <div className="carousel-item h-full">
        <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Hilton} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Hilton</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Palm Jumeirah, Dubai</p>
              </div>
            </div>
        </div>
        <div className="carousel-item h-full">
        <div className='bg-white rounded-xl drop-shadow-xl'>
              <img alt=" " src={Raddison} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Raddison Beach Resort</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Palm Jumeirah, Dubai</p>
              </div>
            </div>
        </div>
        <div className="carousel-item h-full">
        <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Address} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Address Beach Resort</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Marina Beach, Dubai</p>
              </div>
            </div>
        </div>
        <div className="carousel-item h-full">
        <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Atlantis} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Atlantis The Royal</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Palm Jumeirah, Dubai</p>
              </div>
            </div>
        </div>
        <div className="carousel-item h-full">
        <div className='bg-white rounded-xl drop-shadow-xl'>
              <img alt=" " src={FiveJumeirah} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Five Jumeirah Village</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Dubai</p>
              </div>
            </div>

        </div>
      </div>
      <h1 className='text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-2xl lg:text-xl md:text-lg sm:text-lg xs:text-sm text-left poppins mt-[7%] ml-[5%] font-semibold'>Featured Hotel & Resorts</h1>
      {/* -------------------------------BIG SCREEN--------------------------- */}
      <div className="carousel w-full mt-[3%] 3xl:flex 2xl:flex xl:flex lg:flex md:hidden sm:hidden xs:hidden">
        <div id="s1" className="carousel-item relative w-full mx-[10%] ">
          <div className='flex gap-[5%] justify-between px-[9%]' >
            <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Paragraph} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Paragraph Resort</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Shekvetili, Georgia</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Bali} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Ayana Resort</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Bali, Indonesia</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl'>
              <img alt=" " src={Azarbaijan} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Basqal Resort</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Bizlan, Azarbaijan</p>
              </div>
            </div>
          </div>

          <div className="px-[4%] absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <a href="#s3" className="btn btn-circle bg-white drop-shadow-xl">❮</a>
            <a href="#s2" className="btn btn-circle bg-white drop-shadow-xl">❯</a>
          </div>

        </div>
        <div id="s2" className="carousel-item relative w-full">
          <div className='flex gap-[5%] justify-between px-[9%]' >
            <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Hongkong} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Disney Land Hotel</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Lantau Island, Hong Kong</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Italy} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>The First - La Vista di Marina</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Riomaggiore SP, Italy</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl'>
              <img alt=" " src={Paris} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>pullman paris tour eiffel</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Paris, France</p>
              </div>
            </div>
          </div>

          <div className="px-[4%] absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <a href="#s1" className="btn btn-circle bg-white drop-shadow-xl">❮</a>
            <a href="#s3" className="btn btn-circle bg-white drop-shadow-xl">❯</a>
          </div>
        </div>
        <div id="s3" className="carousel-item relative w-full">
          <div className='flex gap-[5%] justify-between px-[9%]' >
            <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Turkey} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Cave Suites</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Cappadocia, Turkey</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Thailand} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Phuket Marriot Resort</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Phuket, Thailand</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl'>
              <img alt=" " src={Singapore} className='w-[700px] h-[350px] 3xl:h-[350px] 2xl:h-[350px] xl:h-[250px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Dusit Thani</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Laguna, Singapore</p>
              </div>
            </div>
          </div>

          <div className="px-[4%] absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
            <a href="#s2" className="btn btn-circle bg-white drop-shadow-xl">❮</a>
            <a href="#s1" className="btn btn-circle bg-white drop-shadow-xl">❯</a>
          </div>
        </div>
      </div>
      {/* -------------------------------SMALL SCREEN--------------------------- */}
      <div className="h-96 carousel carousel-vertical rounded-box mt-[5%] 3xl:hidden 2xl:hidden xl:hidden lg:hidden md:block sm:block xs:block">
  <div className="carousel-item h-full">
  <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Paragraph} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Paragraph Resort</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Shekvetili, Georgia</p>
              </div>
            </div>
  </div> 
  <div className="carousel-item h-full">
  <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Bali} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Ayana Resort</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Bali, Indonesia</p>
              </div>
            </div>
  </div> 
  <div className="carousel-item h-full">
  <div className='bg-white rounded-xl drop-shadow-xl'>
              <img alt=" " src={Azarbaijan} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Basqal Resort</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Bizlan, Azarbaijan</p>
              </div>
            </div>
  </div> 
  <div className="carousel-item h-full">
  <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Hongkong} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Disney Land Hotel</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Lantau Island, Hong Kong</p>
              </div>
            </div>
  </div> 
  <div className="carousel-item h-full">
  <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Italy} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>The First - La Vista di Marina</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Riomaggiore SP, Italy</p>
              </div>
            </div>
  </div> 
  <div className="carousel-item h-full">
  <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Italy} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>The First - La Vista di Marina</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Riomaggiore SP, Italy</p>
              </div>
            </div>
  </div> 
  <div className="carousel-item h-full">
  <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Turkey} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Cave Suites</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Cappadocia, Turkey</p>
              </div>
            </div>
  </div>
  <div className="carousel-item h-full">
  <div className='bg-white rounded-xl drop-shadow-xl overflow-hidden'>
              <img alt=" " src={Thailand} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Phuket Marriot Resort</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Phuket, Thailand</p>
              </div>
            </div>
  </div> 
  <div className="carousel-item h-full">
  <div className='bg-white rounded-xl drop-shadow-xl'>
              <img alt=" " src={Singapore} className='w-[700px] h-[300px]  rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Dusit Thani</p>
                <p className='poppins text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs'>Laguna, Singapore</p>
              </div>
            </div>
  </div> 
</div>
      <div className='relative mx-auto overflow-hidden flex justify-center mt-[5%]  drop-shadow-xl'>
        <img alt=" " src={FiveJumeirah} className='h-[550px] w-[83%] 3xl:w-[83%] 2xl:w-[83%] xl:w-[83%] lg:w-[90%] md:w-[100%] sm:w-[100%] xs:w-[100%] rounded-2xl opacity-85' />
        <div className='absolute top-[22%] text-white w-[60%] 3xl:w-[60%] 2xl:w-[60%] xl:w-[60%] lg:w-[60%] md:w-[70%] sm:w-[70%] xs:w-[70%'>
          <h2 className=' text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl sm:text-2xl xs:text-lg font-semibold'>Your Trusted Partner</h2>
          <p className='text-3xl 3xl:text-3xl 2xl:text-2xl xl:text-xl lg:text-lg md:text-lg sm:text-lg xs:text-sm mt-5 leading-[200%] font-semibold'>Unlock worry-free getaways with us – your trusted partner for 100% guaranteed hotel and resort reservations. Experience seamless stays and exceptional service. Your journey begins with confidence.</p>
        </div>
      </div>
    </div >
  );
}

export default HotelPage;