import BlogBanner from "../BlogComponents/BlogBanner";
import Regulations from "../BlogComponents/Regulations";
function BlogPage() {
    return (
      <div className="pb-[5%] dark:text-black">
         <BlogBanner/>
         <Regulations/>
      </div>
    );
  }
  
  export default BlogPage;