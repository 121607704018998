import './App.css';
import { Routes, Route } from "react-router-dom";

// import TopHeader from './Components/HeaderComponents/top_header';

import Home from "./Pages/Home";
import About from "./Components/HeaderComponents/AboutUs";
import Login from './Components/FormComponents/LoginForm';
import SignupForm from './Components/FormComponents/SignupForm';
import FlightPage from './Components/HeaderComponents/FlightPage';
import VisaPage from './Components/HeaderComponents/VisaPage';
import HotelPage from './Components/HeaderComponents/HotelPage';
import TourPage from './Components/HeaderComponents/TourPage';
import BlogPage from './Components/HeaderComponents/Blog'
import CruisePage from './Components/HeaderComponents/Cruise';
import OurServicesHome from './Components/Cards/OurServicesHome';
import Footer from './Components/FooterComponents/Footer';

function App() {
  return (
    <div className="App">
      {/* <TopHeader/> */}
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="about-us" element={ <About/> } />
        <Route path="login" element={ <Login/> } />
        <Route path="signup" element={ <SignupForm/> } />
        <Route path="flights" element={ <FlightPage/> } />
        <Route path="visa" element={ <VisaPage/> } />
        <Route path="hotel" element={ <HotelPage/> } />
        <Route path="tour" element={ <TourPage/> } />
        <Route path="cruise" element={ <CruisePage/> } />
        <Route path="blog" element={ <BlogPage/> } />
        <Route path="inbound-tours" element={ <OurServicesHome/> } />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
