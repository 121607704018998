import './../../CssComponent/HomePageStyle.css';
import Home from './../../assets/home-blue.png'
import { useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import axios from 'axios';
import { useState, useEffect, useMemo } from 'react';
import countryList from 'react-select-country-list'
import Lottie from 'lottie-react';
import Select from 'react-select';
import SignupLottie from './../../assets/lottiefiles/signup.json'
import parsePhoneNumber from 'libphonenumber-js';
function SignupForm() {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  };

  const goToLogin = () =>{
    navigate('/login')
  }
  const [countryName, setCountryName] = useState('');
  const [mobileInput, setMobileInput] = useState('');
  const [residenceInput, setResidenceInput] = useState('');
  const [error, setError] = useState({});
  const [userExistMessage, setUserExistMessage] = useState('');
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    mobile: mobileInput,
    nationality: countryName,
    residence: residenceInput,
    email: '',
    password: '',
    reenterPassword: '',
  })
  const options = useMemo(() => countryList().getData(), [])

  const countryChangeHandler = (countryName) => {
    setCountryName(countryName);
  }
  const mobileChangeHandler = (mobileInput) => {
    setMobileInput(mobileInput);
  }
  const residenceChangeHandler = (residenceInput) => {
    setResidenceInput(residenceInput);
  }
  formData.nationality = countryName.label
  formData.mobile = mobileInput
  formData.residence = residenceInput.label
  console.log(formData)

  const changeHandler = (e) => {
    const { name, value } = e.target || {};
    if (name) {
      setFormData({ ...formData, [name]: value });
    }
  };

  const selectChangeHandler = (e) => {
    const { name, value } = e.target || {};
    if (name) {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    let nationalitySelected = false;
    let residenceSelected = false;
    
      if (formData.first_name.trim() === '') {
        newErrors['first_name'] = 'First Name is required';
        valid = false;
      }
      if (formData.last_name.trim() === '') {
        newErrors['last_name'] = 'Last Name is required';
        valid = false;
      }
      if (formData.password.trim() === '') {
        newErrors['password'] = 'Password is required';
        valid = false;
      }
      if (formData.reenterPassword.trim() === '') {
        newErrors['reenterPassword'] = 'Enter the Password again';
        valid = false;
      }
      if (formData.nationality === undefined) {
        nationalitySelected = false;
        newErrors['nationality'] = `Nationality is required`;
        valid = false;
      } else {
        // Check if the country field is selected
        nationalitySelected = true; // Assuming 'country' is the key for the country field
      }
      if (formData.residence === undefined) {
        residenceSelected = false;
        newErrors['residence'] = `Residence is required`;
        valid = false;
      } else {
        // Check if the country field is selected
        residenceSelected = true; // Assuming 'country' is the key for the country field
      }
  

    if (!residenceSelected) {
      newErrors['residence'] = 'Country must be selected';
      valid = false;
    }
    if (!nationalitySelected) {
      newErrors['nationality'] = 'Country must be selected';
      valid = false;
    }
    // Check if mobile number format is valid

    const parsedPhoneNumber = parsePhoneNumber(formData.mobile);
    if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
      setError('Invalid phone number');
      return;
    }

    // Check if email format is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors['email'] = 'Invalid email format';
      valid = false;
    }

    // Check if passwords match
    if (formData.password !== formData.reenterPassword) {
      newErrors['reenterPassword'] = 'Passwords do not match';
      valid = false;
    }

    setError(newErrors);
    return valid;
  }

  const checkUserExists = async (email) => {
    try {
      const response = await axios.get(`http://localhost:3000/api/user-exist/${email}`);
      if (response.status === 200) {
        // User found
        console.log('User exists:', response.data);
        return true;
      } else {
        // User not found
        console.log('User does not exist');
        return false;
      }
    } catch (error) {
      console.error('Error checking user existence:', error);
      // Handle other errors, like network issues or server errors
      return false; // Assuming user doesn't exist if there's an error
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const userExists = await checkUserExists(formData.email);
        if (userExists) {
          setUserExistMessage('User Already Exists. Login to Continue');
        } else {
          // User does not exist, proceed with form submission
          const response = await axios.post('http://localhost:3000/api/create-users', formData);
          console.log('User created:', response.data);
          // Clear form data after successful submission
          setFormData({
            first_name: '',
            last_name: '',
            mobile: '',
            nationality: '',
            residence: '',
            email: '',
            password: '',
            reenterPassword: '',
          });
          // Clear nationality, residence, and mobileInput state variables
          setCountryName('');
          setResidenceInput('');
          setMobileInput('');
          setUserExistMessage('');
          goToLogin();
          
        }
      } catch (error) {
        console.error('Error creating user:', error);
        // Handle error (e.g., show error message)
        setError('Failed to register user');
      }
    } else {
      console.log('Form validation failed');
      // Log a message if form validation fails
    }
  };
  
  
  

  return (
    <div className="h-[100%] bg-[#e5f9fe] flex justify-center  p-[3%] 3xl:p-[3%] 2xl:p-[3%] xl:p-[3%] lg:p-[0.5%]">
      
      <div className='flex flex-row 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col justify-center  bg-white rounded-lg w-[60%] 3xl:w-[60%] 2xl:w-[75%] xl:w-[85%] lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%]'>
        <Lottie animationData={SignupLottie} className='w-[40%] mx-auto' />
        <form className='text-lg text-[#3D85C6] w-[40%] 3xl:w-[40%] 2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[100%] sm:w-[100%] xs:w-[100%] p-[5%]  text-left' onSubmit={handleSubmit}>
        <div className='flex justify-end'>
        <button onClick={goToHome} className='text-xs flex flex-col items-center'><img src={Home} className='w-[25px] ' />Home</button>
        </div>
          <h3 className='text-3xl 3xl:text-3xl 2xl:text-3xl xl:text-2xl lg:text-xl md:text-xl sm:text-xl xs:text-xl text-center mx-auto font-semibold'>SignUp</h3>
          <h4 className='text-2xl 3xl:text-2xl 2xl:text-2xl xl:text-xl lg:text-lg md:text-lg sm:text-lg xs:text-lg  leading-[200%] text-center mx-auto'>Welcome to Our family</h4>
          <p className='text-sm'>All fields are <span className='text-red-500 text-sm italic'>* required</span></p>
          {userExistMessage && <span className="text-red-500 text-lg">{userExistMessage}</span>}
          <div className='italic'>
            <div className='flex flex-col border-b-2 border-[#3D85C6] mt-[3%] gap-2'>
              <span className='text-sm'><label>First Name</label></span>
              {error.first_name && <span className="text-red-500 text-sm">{error.first_name}</span>}
              <input type='text' placeholder='Enter First Name Here' name='first_name' value={formData.first_name} onChange={changeHandler} className='dark:bg-white 3xl:text-[17px] 2xl:text-[17px] xl:text-[15px] lg:text-[15px] md:text-[15px] sm:text-[15px] xs:text-[15px] 3xl:3xl:text-[17px] 2xl:text-[17px] xl:text-[15px] lg:text-[15px] md:text-[15px] sm:text-[15px] xs:text-[15px] 2xl:3xl:text-[17px] 2xl:text-[17px] xl:text-[15px] lg:text-[15px] md:text-[15px] sm:text-[15px] xs:text-[15px] xl:text-[15px] lg:text-[15px] md:text-[15px] sm:text-[15px] xs:text-[15px] w-full w-max-xs italic' />
            </div>
            <div className='flex flex-col border-b-2 border-[#3D85C6] mt-[3%] gap-2'>
              <span className='text-sm'><label>Last Name</label></span>
              {error.last_name && <span className="text-red-500 text-sm">{error.last_name}</span>}
              <input type='text' placeholder='Enter Last Name Here' name='last_name' value={formData.last_name} onChange={changeHandler} className='dark:bg-white 3xl:text-[17px] 2xl:text-[17px] xl:text-[15px] lg:text-[15px] md:text-[15px] sm:text-[15px] xs:text-[15px] w-full w-max-xs italic' />
            </div>
            <div className='flex flex-col border-b-2 border-[#3D85C6] mt-[3%] gap-2 dark:bg-white'>
              <span className='text-sm'><label>Mobile</label></span>
              {error.mobile && <span className="text-red-500 text-sm">{error.mobile}</span>}
              <PhoneInput
                international
                defaultCountry="AE"
                name='mobile'
                value={mobileInput}
                onChange={mobileChangeHandler}
                className='dark:bg-white 3xl:text-[17px] 2xl:text-[17px] xl:text-[15px] lg:text-[15px] md:text-[15px] sm:text-[15px] xs:text-[15px] ' />
            </div>
            <div className='flex flex-col mt-[3%] gap-2 '>
              <span className='text-sm'><label>Nationality</label></span>
              {error.nationality && <span className="text-red-500 text-sm">{error.nationality}</span>}
              <Select options={options} value={countryName} name='nationality' onChange={countryChangeHandler} className='dark:bg-white 3xl:text-[17px] 2xl:text-[17px] xl:text-[15px] lg:text-[15px] md:text-[15px] sm:text-[15px] xs:text-[15px]' />
            </div>
            <div className='flex flex-col mt-[3%] gap-2 '>
              <span className='text-sm'><label>Residence</label></span>
              {error.residence && <span className="text-red-500 text-sm">{error.residence}</span>}
              <Select options={options} value={residenceInput} name='residence' onChange={residenceChangeHandler} className='dark:bg-white 3xl:text-[17px] 2xl:text-[17px] xl:text-[15px] lg:text-[15px] md:text-[15px] sm:text-[15px] xs:text-[15px]' />
            </div>
            <div className='flex flex-col border-b-2 border-[#3D85C6] mt-[3%] gap-2'>
              <span className='text-sm'><label>Email</label></span>
              {error.email && <span className="text-red-500 text-sm">{error.email}</span>}
              <input type='email' name='email' value={formData.email} onChange={changeHandler} placeholder='Enter email address Here' className='dark:bg-white 3xl:text-[17px] 2xl:text-[17px] xl:text-[15px] lg:text-[15px] md:text-[15px] sm:text-[15px] xs:text-[15px] w-full w-max-xs italic' />
            </div>
            <div className='flex flex-col border-b-2 border-[#3D85C6] mt-[3%] gap-2'>
              <span className='text-sm'><label>Password</label></span>
              {error.password && <span className="text-red-500 text-sm">{error.password}</span>}
              <input type='password' name='password' value={formData.password} onChange={changeHandler} placeholder='Create Password' className='dark:bg-white 3xl:text-[17px] 2xl:text-[17px] xl:text-[15px] lg:text-[15px] md:text-[15px] sm:text-[15px] xs:text-[15px] w-full w-max-xs italic' />
            </div>
            <div className='flex flex-col border-b-2 border-[#3D85C6] mt-[3%] gap-2'>
              <span className='text-sm'><label>Re-Enter Password</label></span>
              {error.reenterPassword && <span className="text-red-500 text-sm">{error.reenterPassword}</span>}
              <input type='password' name='reenterPassword' value={formData.reenterPassword} onChange={changeHandler} placeholder='Re-enter Password' className='dark:bg-white 3xl:text-[17px] 2xl:text-[17px] xl:text-[15px] lg:text-[15px] md:text-[15px] sm:text-[15px] xs:text-[15px] w-full w-max-xs italic' />
            </div>
          </div>
          <div className='flex justify-center mt-10 text-white'>
            <button type='submit' className='bg-[#3D85C6] px-5 w-[50%] py-2 rounded-lg 3xl:text-[17px] 2xl:text-[17px] xl:text-[15px] lg:text-[15px] md:text-[15px] sm:text-[15px] xs:text-[15px]'>Signup</button>
          </div>
        </form>
      </div>


     
    </div>
  );
}

export default SignupForm;