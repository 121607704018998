import Dubai from './../../assets/home-page/countries/dubai.jpg'
import Georgia from './../../assets/home-page/countries/georgia.jpg'
import Greece from './../../assets/home-page/countries/greece.jpg'
import Saudi from './../../assets/home-page/countries/saudi-arabia.jpg'
import Oman from './../../assets/home-page/countries/oman.jpg'
import Azarbaijan from './../../assets/home-page/countries/azerbaijan.jpg'
import France from './../../assets/home-page/countries/france.jpg'
import Thailand from './../../assets/home-page/countries/thailand.jpg'
import Spain from './../../assets/home-page/countries/spain.jpg'
function PopularDestinations() {
    return (
        <div className='bg-white bg-opacity-80 py-[5%] px-[5%] mt-[5%] dark:text-[#000000]'>
             <h5 className='text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-lg xs:text-sm '>Our Popular Destinations</h5>
      <div className="flex gap-5  justify-center mt-[5%] items-center h-[20%]">
        <div className='relative overflow-hidden 3xl:block 2xl:block xl:block lg:block md:hidden sm:hidden xs:hidden '>
        <button className=''><img alt=" " src={Azarbaijan} width={390} className='hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125 transition-transform duration-300 transform scale-100 hover:scale-125'/></button>
        <p className='absolute bottom-[10%] text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-lg sm:text-sm xs:text-sm text-white left-[15%] xl:left-[13%] lg:left-[6%] md:left-[1%]'>Azarbaijan</p>
        </div>
        <div className='flex flex-col gap-5  '>
        <div className='relative overflow-hidden'>
        <button><img alt=" " src={Dubai} width={260} className='hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125'/></button>
        <p className='absolute bottom-[10%] 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-lg sm:text-sm xs:text-sm text-5xl text-white left-[30%]'>UAE</p>
        </div>
        <div className='relative overflow-hidden'>
        <button><img alt=" " src={Spain} width={260} className='hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125'/></button>
        <p className='absolute bottom-[10%] text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-lg sm:text-sm xs:text-sm text-white left-[23%] xl:left-[19%] lg:left-[10%]'>Spain</p>
        </div>
        </div>
        <div className='flex flex-col gap-5'>
        <div className='relative overflow-hidden'>
        <button><img alt=" " src={Georgia} width={400} className='hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125'/></button>
        <p className='absolute bottom-[10%] text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-lg sm:text-sm xs:text-sm text-white left-[25%] xl:left-[23%] lg:left-[18%]'>Georgia</p>
        </div>
        <div className='relative overflow-hidden'>
        <button><img alt=" " src={Saudi} width={400} className='hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125'/></button>
        <p className='absolute bottom-[10%] text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-lg sm:text-sm xs:text-sm text-white left-[30%] lg:left-[25%]'>Saudi</p>
        </div>
        <div className='relative overflow-hidden'>
        <button><img alt=" " src={Thailand} width={400} className='hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125'/></button>
        <p className='absolute bottom-[10%] text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-lg sm:text-sm xs:text-sm text-white left-[23%] xl:left-[18%] lg:left-[10%] '>Thailand</p>
        </div>
        </div>
        <div className='flex flex-col gap-5'>
        <div className='relative overflow-hidden'>
        <button><img alt=" " src={Oman} width={250} className='hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125'/></button>
        <p className='absolute bottom-[10%] text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-lg sm:text-sm xs:text-sm text-white left-[17%] xl:left-[13%] lg:left-[6%]'>Oman</p>
        </div>
        <div className='relative overflow-hidden'>
        <button><img alt=" " src={Greece} width={250} className='hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125'/></button>
        <p className='absolute bottom-[10%] text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-lg sm:text-sm xs:text-sm text-white left-[13%] xl:left-[8%] lg:left-[3%]'>Greece</p>
        </div>
        </div>
        <div className='relative overflow-hidden 3xl:block 2xl:block xl:block lg:block md:hidden sm:hidden xs:hidden '>
        <button><img alt=" " src={France} width={444} className='hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125'/></button>
        <p className='absolute bottom-[10%] text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-lg sm:text-sm xs:text-sm text-white left-[30%] xl:left-[28%]'>France</p>
        </div>
      </div>
      </div>
    );
  }

  export default PopularDestinations;