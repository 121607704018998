//import TopHeader from "../Components/HeaderComponents/top_header";
import Banner from "../Components/BodyComponents/Banner";
import SideSocialMedia from "../Components/SocialMedia/SocialMediaSide";
import WhoWeAre from "../Components/BodyComponents/WhoWeAre";
import WhyUs from "../Components/BodyComponents/WhyUs";
import PopularDestinations from "../Components/BodyComponents/PopularDestinations";
import Services from "./../Components/BodyComponents/services"
import TopHeader from "../Components/HeaderComponents/top_header";
import { Helmet } from 'react-helmet';
// CSS
// import './../CssComponent/HomePageStyle.css';

function Home() {
    return (
      <div className="relative h-[100%] w-[100%] lg:w-[100%] dark:text-black">
        <Helmet>
        <title>Shaliha Tourism - Your Gateway to Seamless Travel</title>
        <meta name="description" content="Discover the world with Shaliha Tourism. We specialize in visa processing, flight ticket booking, tour packages, cruise and yacht experiences, and provide a curated selection of hotels and resorts for your perfect getaway." />
        {/* Add more meta tags as needed */}
      </Helmet>
        <TopHeader/>
        <Banner/>
        <SideSocialMedia />
        <PopularDestinations/>
        <WhoWeAre/>
        <Services/>
        <WhyUs/>
      </div>
    );
  }
  
  export default Home;