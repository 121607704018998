import DesertSafari from './../../assets/our-services-images/desert-safari.jpg'
import DuneBuggy from './../../assets/our-services-images/dune-buggy.jpg'
import QuadBiking from './../../assets/our-services-images/quad.jpg'
import HelicopterTour from './../../assets/our-services-images/helicopter tour.jpg'
import LimousineRide from './../../assets/our-services-images/limousine-ride.jpg'
import LuxuryBus from './../../assets/our-services-images/Luxury-Bus.jpg'
import HotAirBalloon from './../../assets/our-services-images/hot-air-balloon.jpg'
import jetski from './../../assets/our-services-images/jetski-ride.jpg'
import SpeedBoat from './../../assets/our-services-images/speedboat.jpg'
import FishingTrip from './../../assets/our-services-images/fishing-in-Dubai.jpg'
import ImgWorld from './../../assets/our-services-images/img-world.jpeg'
import FerrariWorld from './../../assets/our-services-images/ferrari-world.jpg'
import WarnerBros from './../../assets/our-services-images/warner-bros.jpg'
import DubaiPark from './../../assets/our-services-images/dubai-park.jpg'
import Legoland from './../../assets/our-services-images/legoland-dubai.jpg'
import SkyDubai from './../../assets/our-services-images/sky-dubai.jpg'
import BurjKhalifa from './../../assets/our-services-images/burj-khalifa.jpg'
import UnderwaterZoo from './../../assets/our-services-images/aquarium-under-waterzoo.jpg'
import GreenPlanet from './../../assets/our-services-images/Green-Planet-Dubai.jpg'
import Acquaventure from './../../assets/our-services-images/acquaventure.jpg'
import WildWadiWaterPark from './../../assets/our-services-images/wild-wadi-waterpark.jpg'
import YasWaterPark from './../../assets/our-services-images/Yas-Water-World.jpeg'
import DolphinShow from './../../assets/our-services-images/dolphin-show.jpg'
import GlobalVillage from './../../assets/our-services-images/global-village.jpg'
import MiracleGarden from './../../assets/our-services-images/miracle-garden.jpg'
import GlowGarden from './../../assets/our-services-images/glow-garden.jpg'
import SeaWorld from './../../assets/our-services-images/sea-world.jpg'
import ScubaDiving from './../../assets/our-services-images/scuba-diving-dubai.jpg'
import Zipline from './../../assets/our-services-images/zipline-marina.jpg'
import DinnerCruise from './../../assets/our-services-images/dinner-cruise.jpg'
import DubaiMuseum from './../../assets/our-services-images/dubai-museum.jpg'
import paramotor from './../../assets/our-services-images/paramotor.jpg'
import ThePalmMonorail from './../../assets/our-services-images/ThePalmMonorail.jpg'
import Expo from './../../assets/our-services-images/Expo-City.jpg'
import AlAinCity from './../../assets/our-services-images/al-ain-city.jpg'
import Atlantis from './../../assets/our-services-images/atlantis-dinner.jpg'
import BigBusTour from './../../assets/our-services-images/big-bus-tour.jpg'
import chillout from './../../assets/our-services-images/chillout-ice-lounge.jpg'
import crabHunting from './../../assets/our-services-images/crab-hunting.jpg'
import crocodilePark from './../../assets/our-services-images/crocodile-park.jpeg'
import DreamscapeVR from './../../assets/our-services-images/dreamscape-vr-adventure.jpg'
import Fujairah from './../../assets/our-services-images/fujairah-east-coast.jpg'
import HattaWadi from './../../assets/our-services-images/hatta-wadi-activities.jpg'
import SkyDinner from './../../assets/our-services-images/sky-dinner.jpg'
import DubaiFrame from './../../assets/our-services-images/the-dubai-frame.jpg'
import ThreeDWorld from './../../assets/our-services-images/3d-world-selfie-museum.jpg'
import SafariPark from './../../assets/our-services-images/safari-park-explorer.jpg'
import BananaBoatRide from './../../assets/our-services-images/banana-boat-ride.jpg'


import { useNavigate } from 'react-router-dom';
//Icons
import BookNow from './../../assets/icons/book-now.svg';

// CSS
import './../../CssComponent/HomePageStyle.css';


function OurServicesHome() {

  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
};
// Handle form submission
const handleSearch = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    const phoneNumber = '+971544544971';
    const whatsappLink = `https://wa.me/${phoneNumber}`;

    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };

    return (
        <div className="h-[100%] bg-white dark:text-black text-black p-[5%] pb-[20%] 3xl:pb-[35%] 2xl:pb-[65%] xl:pb-[80%] lg:pb-[70%] md:pb-[145%] sm:pb-[175%] xs:pb-[340%]" >
          
          <div className='text-[#3D85C6] p-5 sticky top-0 z-10 bg-white  rounded-b-xl '>
            <div className='flex justify-end mb-5'>
          <button onClick={goToHome} className='underline text-[#3D85C6] '>Home</button>
          </div>
            <div className='flex justify-between gap-5 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col '>
             <h2 className="text-left text-5xl text-5xl text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl sm:text-2xl xs:text-lg poppins font-semibold text-[#3D85C6]">Inbound Tours UAE</h2>
             <button className=' px-[5%] py-3 rounded-xl drop-shadow-lg bg-[#3D85C6] text-white font-semibold flex justify-center gap-5 items-center text-lg ' onClick={handleSearch}>Book Now &nbsp; <img src={BookNow} className='w-[30px]' alt='' /> </button>
             </div>
             <p className='text-left mt-3'>See all tourist spots to get the plan of your inbound tours across UAE</p>
             </div>
             <div className='mt-[5%] 3xl:mt-[5%] 2xl:mt-[5%] xl:mt-[5%] lg:mt-[5%] md:mt-[10%] sm:mt-[10%] xs:mt-[10%] grid grid-cols-5 3xl:grid-cols-5 2xl:grid-cols-5 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1 gap-[3%] 3xl:gap-[3%] 2xl:gap-[3%] xl:gap-x-[4%] xl:gap-y-[1%] lg:gap-y-[0.5%] lg:gap-x-[4%] md:gap-y-[0.5%] sm:gap-y-[0.5%] xs:gap-y-[0.2%] 3xl:p-0 2xl:p-0 xl:p-0 lg:p-0 md:pb-[55%] sm:pb-[55%] xs:pb-[60%] ' >
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={DesertSafari} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:[250px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Desert Safari</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={DuneBuggy} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Dune Buggy</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl '>
              <img alt=" " src={HelicopterTour} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Helicopter Tour</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl  '>
              <img alt=" " src={LuxuryBus} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Luxury Coach Bus</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={jetski} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Jetski Ride</p>
              </div>
            </div>
            {/* --------------------- */}
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={WarnerBros} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:[250px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Warner Bros World</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={DubaiPark} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Dubai Motiongate Park</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl '>
              <img alt=" " src={Legoland} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Legoland</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl  '>
              <img alt=" " src={QuadBiking} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Quad Biking</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={LimousineRide} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Limousine Ride</p>
              </div>
            </div>
            {/* --------------------- */}
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={HotAirBalloon} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:[250px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Hot Air Balloon</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={SpeedBoat} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Speed Boat Ride</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl '>
              <img alt=" " src={FishingTrip} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Fishing Trip</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl  '>
              <img alt=" " src={ImgWorld} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Img World</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={FerrariWorld} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Ferrari World</p>
              </div>
            </div>
            {/* --------------------- */}
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={WarnerBros} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:[250px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Warner Bros World</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={SkyDubai} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Sky Dubai</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl '>
              <img alt=" " src={BurjKhalifa} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Burj Khalifa</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl  '>
              <img alt=" " src={UnderwaterZoo} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Underwater Zoo Acquarium</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={GreenPlanet} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>The Green Planet</p>
              </div>
            </div>
            {/* --------------------- */}
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={Acquaventure} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:[250px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Acquaventure Water Park</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={WildWadiWaterPark} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Wild Wadi Water Park</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl '>
              <img alt=" " src={YasWaterPark} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Yas Water Park</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl  '>
              <img alt=" " src={DolphinShow} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Dolphin Show</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={GlobalVillage} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Global Village</p>
              </div>
            </div>
            {/* --------------------- */}
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={MiracleGarden} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:[250px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Miracle Garden</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={GlowGarden} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Glow Garden</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl '>
              <img alt=" " src={SeaWorld} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Sea World</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl  '>
              <img alt=" " src={ScubaDiving} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Scuba Diving</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={Zipline} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Zipline Marina</p>
              </div>
            </div>
            {/* --------------------- */}
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={DinnerCruise} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:[250px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Dinner Cruise</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={ThreeDWorld} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>3D World Selfie Museum</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl '>
              <img alt=" " src={AlAinCity} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Al Ain City</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl  '>
              <img alt=" " src={Atlantis} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Atlantis Dinner or Lunch</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={BigBusTour} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Big Bus Tour</p>
              </div>
            </div>
            {/* --------------------- */}
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={chillout} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:[250px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Chillout Ice Lounge</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={crabHunting} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>crab Hunting</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl '>
              <img alt=" " src={crocodilePark} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Crocodile Park</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl  '>
              <img alt=" " src={DreamscapeVR} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Dreamscape VR Adventure</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={DubaiMuseum} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Dubai Museum</p>
              </div>
            </div>
            {/* --------------------- */}
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={Expo} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:[250px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Expo City</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={Fujairah} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Fujairah East Coast</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl '>
              <img alt=" " src={HattaWadi} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Hatta Wadi Activities</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl  '>
              <img alt=" " src={paramotor} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Paramotor Desert Adventure</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={SafariPark} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Safari Park</p>
              </div>
            </div>
            {/* --------------------- */}
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={SkyDinner} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:[250px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Sky Dinner</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={DubaiFrame} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Dubai Frame</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl '>
              <img alt=" " src={ThePalmMonorail} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm font-semibold'>The Palm Monorail</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl '>
              <img alt=" " src={BananaBoatRide} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm font-semibold'>Banana Boat Ride</p>
              </div>
            </div>
          </div>

        </div>
    );
}

export default OurServicesHome;
