import { useState, useEffect } from 'react';
import './../../CssComponent/HomePageStyle.css';
import Lottie from 'lottie-react';
import loginLottie from './../../assets/lottiefiles/login.json';
import logo from './../../assets/shalihaLogo.jpg'
import User from './../../assets/login/user.png';
import Lock from './../../assets/login/lock.png';
import closed from './../../assets/login/eye-closed.svg';
import show from './../../assets/login/eye-open.svg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthService from '../authService';
import { useUser } from '../../userContext';

function Login() {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: '',
  })
  const [error, setError] = useState({});
  const [userNotExistMessage, setUserNotExistMessage] = useState('')
  const [invalidMessage, setInvalidMessage] = useState('')
  const [commonError, setCommonError] = useState('');
  const [input, setInput] = useState({
    icon: closed,
    type: 'password',
    class: 'swap-off fill-current w-7 h-10'
  });
  const [token, setToken] = useState('');
  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name) {
      setLoginFormData({ ...loginFormData, [name]: value });
    }
  }

  const goToHome = () => {
    navigate('/');
  };
  const goToSignup = () => {
    navigate('/signup');
  };
  const changeInputClose = () => {
    const type = input.type
    if (type === 'password') {
      setInput({
        type: 'text',
        icon: show,
        class: 'swap-on fill-current w-7 h-10'
      })
    }
    else {
      setInput({
        type: 'password',
        icon: closed,
        class: 'swap-off fill-current w-7 h-10'
      })
    }

  }


  useEffect(() => {
    console.log('Input state changed:', input);
  }, [input]);


  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    for (const key in loginFormData) {
      if (loginFormData[key].trim() === '') {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
        valid = false;
      }
    }
    // Check if email format is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(loginFormData.email)) {
      newErrors['email'] = 'Invalid email format';
      valid = false;
    }
    setError(newErrors);
    return valid;
  }
  // const getFormData = async (email, password) => {
  //   try {
  //     const response = await axios.get(`http://localhost:3000/api/user-login/${email}/${password}`);
  //     const user = response.data.user
  //     if (response.status === 200) {       
  //       if (user.email === loginFormData.email && user.password === loginFormData.password) {  
  //         goToHome();
  //         // User found
  //         console.log('User exists:', user.email, response.status);
  //         return true;
  //       }
  //     }
  //       if (user.email !== loginFormData.email || user.password !== loginFormData.password){
  //         alert('invalid!')
  //         setInvalidMessage("Invalid email or Invalid Password. Please check")
  //       console.log('Invalid Email or Password');
  //       return false;
  //       }
  //       else if (user.email !== loginFormData.email && user.password !== loginFormData.password) {
  //         alert('no user exist')
  //         console.log("usernot exist")
  //         setUserNotExistMessage("User Doesn't Exist. Please")
  //         return false;
  //       }  

  //   } catch (error) {
  //     console.error('Error checking user existence:', error);
  //     // Handle other errors, like network issues or server errors
  //     return false; // Assuming user doesn't exist if there's an error
  //   }
  // }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post('http://localhost:3000/api/login', loginFormData);
        const serverToken = response.data.token;
        const userData = response.data.user;
        localStorage.setItem('user', JSON.stringify(userData));
        setUser(userData.first_name, userData.last_name, userData.email);
        AuthService.setToken(serverToken);
        setToken(serverToken);

        goToHome();
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            setInvalidMessage('Invalid password. Please try again.');
          } else if (error.response.status === 401) {
            setUserNotExistMessage('User not found. Sign up to continue.');
          } else {
            setCommonError('An error occurred. Please try again.');
          }
        } else {
          alert('Error')
          setCommonError('An error occurred. Please try again.');
          navigate('/');
        }

      }

    }
  }
  return (
    <div className="h-[88.7vh] 3xl:h-[88.7h] 2xl:h-[88.7vh] xl:h-[88.7vh] lg:h-[90.5vh] md:h-[92.2vh] sm:h-[93.3vh] xs:h-[94.5vh] flex justify-center items-center dark:text-[#000000]  relative overflow-hidden">
      <div className='bg-white w-[50%] 3xl:w-[50%] 2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[90%] sm:w-[90%] xs:w-[95%] rounded-xl p-[2%] drop-shadow-xl z-10  opacity-90'>
        <div className='flex justify-between'>
          <img alt=" " src={logo} className='w-[5%]' />
          <p>New User ? <span className='text-[#3D85C6] font-semibold'><button onClick={goToSignup}>Signup</button></span></p>
        </div>
        <div className='flex justify-center items-center 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col'>
          <Lottie animationData={loginLottie} className='w-[40%] 3xl:w-[40%] 2xl:w-[50%] xl:w-[60%] lg:w-[70%] md:w-[40%] sm:w-[60%] xs:w-[60%]' />

          <form className=' text-left bg-white p-5 text-[#3D85C6] w-[50%] 3xl:w-[50%] 2xl:w-[60%] xl:w-[70%] lg:w-[80%] md:w-[95%] sm:w-[95%] xs:w-[95%] gap-[5%]' onSubmit={handleSubmit}>
            <h3 className='text-3xl mt-[2%] font-semibold text-left 3xl:text-3xl 2xl:text-2xl xl:text-xl lg:text-lg md:text-lg sm:text-lg xs:text-sm'>Welcome Back !</h3>
            <p className='text-black'>Login to continue</p>
            {userNotExistMessage && <p className='text-md font-semibold text-[#000000]'>{userNotExistMessage} <a className='text-lg text-[#3D85C6]' onClick={goToHome} href='#home' >Signup</a></p>}
            {invalidMessage && <p className='text-md font-semibold text-[#000000]'>{invalidMessage}</p>}
            {commonError && <p className='text-md font-semibold text-[#000000]'>{commonError}</p>}
            <div className='mt-[5%]'>
              {error.email && <span className="text-red-500 text-sm">{error.email}</span>}
              <div className='flex gap-1  p-2 py-3 border-2 border-[#3D85C6] '>
                <img alt=" " src={User} className='w-[6%]' />
                <input type='email' placeholder='Email' className='w-full text-[#3D85C6] dark:bg-white ' name='email' value={loginFormData.email} onChange={changeHandler} />
              </div>
            </div>
            <div className='mt-[5%]'>
              {error.password && <span className="text-red-500 text-sm">{error.password}</span>}
              <div className='flex items-center gap-1 p-2 py-0 border-2 border-[#3D85C6]'>
                <img alt=" " src={Lock} className='w-[7%]' />
                <input type={input.type} placeholder='password' name='password' value={loginFormData.password} onChange={changeHandler} className='w-full text-[#3D85C6] dark:bg-white' />
                <div className='self-center'>
                  <label className="swap swap-rotate">
                    <input type="checkbox" className="theme-controller" />
                    <img alt=" " src={input.icon} onClick={changeInputClose} className={input.class} />
                  </label>
                </div>
              </div>
            </div>
            <div className='flex 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col justify-between mt-[7%] items-center'>
              <button type='submit' className='bg-[#3D85C6] text-white rounded-full w-[30%] 3xl:w-[30%] 2xl:w-[30%] xl:w-[30%] lg:w-[30%] md:w-[30%] sm:w-[50%] xs:w-[60%] p-2'>Login</button>
              <button className='uppercase'>Forgot Password ?</button>
            </div>
          </form>
        </div>
      </div>
      <div className='absolute bg-[#3D85C6] bottom-[-200%] w-[120%] h-[275%] 3xl:h-[275%] 2xl:h-[275%] xl:h-[265%] lg:h-[260%] md:h-[260%] sm:h-[270%] xs:h-[275%] rounded-full z-0 border-[25px] border-white drop-shadow-xl'></div>
    </div>
  );
}

export default Login;