import TicketBooking from './../../assets/home-page/flight-booking.jpg';
import Visa from './../../assets/home-page/visa-approved.jpg';
import Hotel from './../../assets/home-page/hotel.jpg';
import Tour from './../../assets/home-page/tour.jpg';
import Inbound from './../../assets/home-page/uae-tourism.png';
import Insurance from './../../assets/home-page/travel-insurance.jpg';
import './../../CssComponent/HomePageStyle.css';
function Services() {
    return (
      <div className="px-[5%] mt-[5%] 3xl:px-[10%]">
        <h1 className='text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-lg xs:text-sm'>Our Services</h1>
        <div className="grid grid-cols-5 3xl:grid-cols-6 2xl:grid-cols-6 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 xs:grid-cols-2 mt-[5%] gap-5  justify-center items-center">
            <div className='bg-white rounded-xl drop-shadow-xl dark:drop-shadow-sm overflow-hidden'>
                <img alt=" " src={TicketBooking} className='w-[400px] scale-[140%] rounded-t-xl drop-shadow-xl dark:drop-shadow-sm dark:drop-shadow-sm' />
                <p className='p-3 poppins text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm mt-8'>Ticket Booking</p>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl dark:drop-shadow-sm overflow-hidden'>
                <img alt=" " src={Visa} className='w-[400px] scale-[140%] rounded-t-xl drop-shadow-xl dark:drop-shadow-sm' />
                <p className='p-3 poppins text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm mt-8'>Visa Process</p>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl dark:drop-shadow-sm'>
                <img alt=" " src={Hotel} className='w-[400px] rounded-t-xl drop-shadow-xl dark:drop-shadow-sm' />
                <p className='p-3 poppins text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm 3xl:mt-0 2xl:mt-3'>Hotels and Resorts</p>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl dark:drop-shadow-sm'>
                <img alt=" " src={Tour} className='w-[400px] rounded-t-xl drop-shadow-xl dark:drop-shadow-sm' />
                <p className='p-3 poppins text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm 3xl:mt-0 2xl:mt-3'>Holiday Packages</p>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl dark:drop-shadow-sm overflow-hidden'>
                <img alt=" " src={Inbound} className='w-[400px] scale-[125%] rounded-t-xl drop-shadow-xl dark:drop-shadow-sm' />
                <p className='p-3 poppins text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm mt-7'>UAE Inbound Tours</p>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl dark:drop-shadow-sm overflow-hidden'>
                <img alt=" " src={Insurance} className='w-[400px] scale-[125%] rounded-t-xl drop-shadow-xl dark:drop-shadow-sm' />
                <p className='p-3 poppins text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm mt-7 '>Travel Insurance</p>
            </div>
            </div>
      </div>
    );
  }
  
  export default Services;