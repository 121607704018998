import GiantWheel from './../../assets/icons/landing-page/giant-wheel.jpg';
import './../../CssComponent/HomePageStyle.css';
function WhoWeAre() {

  return (
    <div className=" px-[10%] mt-[5%] dark:text-[#000000]">

      <div className='flex justify-center 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col drop-shadow-xl dark:drop-shadow-sm '>
        <div className='bg-white bg-opacity-40 w-[60%] 2xl:w-[50%] md:w-[100%] sm:w-[100%] xs:w-[100%] text-left mt-[5%] pl-[5%] cursive '>
          <div className='pr-[5%] pl-[0] pt-[20%] 3xl:pt-[20%] 2xl:pt-[20%] xl:pt-[10%] xl:pb-[10%] lg:pt-[10%] lg:pb-[10%] md:pt-[10%] md:pb-[10%] smpt-[10%] sm:pb-[10%] xs:pt-[10%] xs:pb-[10%]'>
            <h3 className="text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-lg xs:text-sm font-semibold">Who We Are</h3>
            <p className='text-xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-md md:text-sm sm:text-xs xs:text-xs mt-[5%] lg:indent-32 md:indent-16 sm:indent-16 xs:indent-16  indent-40 leading-[200%]'>Welcome to Shaliha Tourism, your trusted partner in crafting unforgettable travel experiences worldwide. With a passion for exploration and a commitment to seamless journeys, we take pride in being your one-stop destination for all your travel needs.</p>

            <p className='mt-5 text-xl indent-40 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-md md:text-sm sm:text-xs xs:text-xs mt-[5%] lg:indent-32 md:indent-16 sm:indent-16 xs:indent-16 leading-[200%]'>At Shaliha Tourism, we understand that every traveler seeks more than just a trip; they seek moments that linger in memory, experiences that inspire, and destinations that captivate the soul. With this in mind, we offer a comprehensive suite of services tailored to meet your travel desires.</p>
            <p className='3xl:block 2xl:block xl:hidden lg:hidden md:hidden sm:hidden xs:hidden mt-5 text-xl indent-40 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-md md:text-sm sm:text-xs xs:text-xs mt-[5%] lg:indent-32 md:indent-16 sm:indent-16 xs:indent-16 leading-[200%]'>Embark on a journey with Shaliha Tourism, where every detail is meticulously crafted to transform your travel aspirations into cherished memories. Let us be the architects of your next adventure!</p>
          </div>
          
        </div>
        <div className=' drop-shadow-xl dark:drop-shadow-sm'>
          <img alt=" " src={GiantWheel} className='w-[550px] 3xl:w-[550px] 2xl:w-[550px] xl:w-[750px] lg:w-[600px] md:w-[350px] sm:w-[350px]' />
        </div>
      </div>
    </div>
  );
}

export default WhoWeAre;