import Banner from './../../assets/tour_page/tour-banner2.jpg';
import Search from './../../assets/icons/visa-page-images/search.png';
import Calendar from './../../assets/icons/header-icons/monthly-calendar-svgrepo-com.svg';
import './../../CssComponent/HomePageStyle.css'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import Arrow from './../../assets/right-arrow-white.svg'
import { useNavigate } from 'react-router-dom';

import DesertSafari from './../../assets/our-services-images/desert-safari.jpg'
import FerrariWorld from './../../assets/our-services-images/ferrari-world.jpg'
import WarnerBros from './../../assets/our-services-images/warner-bros.jpg'
import BurjKhalifa from './../../assets/our-services-images/burj-khalifa.jpg'
import UnderwaterZoo from './../../assets/our-services-images/aquarium-under-waterzoo.jpg'

import Norway from '../../assets/tour_page/norway.jpg'
import Cappadocia from '../../assets/tour_page/cappadocia.jpg'
import switzerland from '../../assets/tour_page/switzerland.jpg'
import Maldives from '../../assets/tour_page/maldives.jpg'
import Dubai from '../../assets/home-page/countries/dubai.jpg'
import Vacation from '../../assets/tour_page/vacation.jpg'

import Abudhabi from '../../assets/home-page/countries/abudhabi.jpg';
import Georgia from '../../assets/home-page/countries/georgia.jpg';
import Thailand from '../../assets/home-page/countries/thailand.jpg';
import Bali from '../../assets/home-page/countries/bali.jpg';
import Saudi from '../../assets/home-page/countries/saudi-arabia.jpg';
import Spain from '../../assets/home-page/countries/spain.jpg'

import { useState } from 'react';
import SideSocialMedia from '../SocialMedia/SocialMediaSide';
import TopHeader from './top_header';
import { Helmet } from 'react-helmet';
function TourPage() {
  const navigate = useNavigate();
  const goToInboundTours = () => {
    navigate('/inbound-tours');
};
  // ------------------------------------------------------

  const [startDate, setStartDate] = useState(new Date());

  const handleStartDateChange = (date) => {
    setStartDate(date);
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
  };

  // ------------------------------------------------------

  // Handle form submission
  const handleSearch = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    const phoneNumber = '+971544544971';
    const whatsappLink = `https://wa.me/${phoneNumber}`;

    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };
    return (
      <div className="h-[100%] dark:text-[#000000] pb-[5%]">
         <Helmet>
        <title>Holiday Tour Packages</title>
        <meta name="description" content="Explore exciting holiday tour packages with amazing destinations and activities." />
      </Helmet>
        <TopHeader/>
        <SideSocialMedia/>
        <div className='h-[100vh] relative z-0'>
          <div className='poppins w-[70%] 3xl:w-[70%] 2xl:w-[70%] xl:w-[75%] lg:w-[95%] md:w-[95%] sm:w-[95%] xs:w-[92%] mt-[10%] 3xl:mt-[10%] 2xl:mt-[10%] xl:mt-[15%] lg:mt-[22%] md:mt-[25%] sm:mt-[30%] xs:mt-[35%] right-[2%] 3xl:right-[2%] 2xl:right-[2%] xl:right-[1%] px-[8%] text-left absolute z-10 text-white italic'>
            <h4 className='text-7xl 3xl:text-7xl 2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl xs:text-lg font-semibold'>Effortless Journeys, Lasting Memories</h4>
            <p className='text-3xl 3xl:text-3xl 2xl:text-2xl xl:text-xl lg:text-lg md:text-lg sm:text-sm xs:text-sm mt-5 leading-[200%] 3xl:leading-[200%] 2xl:leading-[200%] xl:leading-[200%] lg:leading-[200%] md:leading-[200%] sm:leading-[200%] xs:leading-[200%]'>Craft memories across borders, spend quality time in different countries. Your easy vacation starts with us, turning travel dreams into cherished moments.</p>
            {/* ----------------------BIG SCREEN------------------ */}
            <div className='bg-white opacity-90 w-[100%]  p-5 rounded-xl drop-shadow-xl mt-[5%] 3xl:block 2xl:block xl:block lg:block md:hidden sm:hidden xs:hidden z-10'>
            <form className='flex items-center   text-black' onSubmit={handleSearch}>
            <input type='text' placeholder='Search Country' className='dark:bg-white input 3xl:border-0 focus:outline-0 2xl:border-0 xl:border-0 lg:border-0 md:input-bordered text-lg poppins 3xl:w-[75%] 2xl:w-[75%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] text-lg 3xl:text-lg 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs  ' />
                <div className='input-container  3xl:w-[25%] 2xl:w-[25%] xl:w-[25%] lg:w-[25%] md:w-[25%] sm:w-[25%] xs:w-[25%] text-lg 3xl:text-lg 2xl:text-sm xl:text-sm lg:text-sm md:text-xs sm:text-xs xs:text-xs' id="date-picker-container">
                  <label for="date-from" className='flex gap-3 uppercase poppins items-center not-italic'><img alt=" " src={Calendar} width={20} />travel Date</label>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat="EEE MMM d, yyyy"
                    className="border rounded-md  py-2 outline-none  poppins z-10 "
                  />
                </div>
                <button type='submit' className='bg-[#0077b3] w-[25%] text-white rounded-xl drop-shadow-xl py-5 drop-shadow-xl'>Search</button>
                </form>
            </div>
            {/* ----------------------SMALL SCREEN------------------ */}
            <div className='3xl:hidden 2xl:hidden xl:hidden lg:hidden md:block sm:block xs:block w-[100%] text-black mt-5'>
            <form className=' text-lg 3xl:text-lg 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs  ' onSubmit={handleSearch}>
              <input type='text' placeholder='Search Country' className='input input-bordered w-full w-max-xs rounded-full dark:bg-white text-lg poppins 3xl:w-[75%] 2xl:w-[75%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] text-lg 3xl:text-lg 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs border-r-2 ' />
              <div className='input-container flex items-center between gap-[15%] mt-5 bg-white  input input-bordered w-full w-max-xs rounded-full p-2 px-3 text-lg  md:text-sm sm:text-sm xs:text-xs' id="date-picker-container">
                <label for="date-from" className='flex gap-3 uppercase poppins items-center not-italic whitespace-nowrap'><img alt=" " src={Calendar} width={20} />Select date</label>
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="EEE MMM d, yyyy"
                  className="border rounded-md  outline-none  poppins z-10 "
                />
              </div>
              <div className='flex justify-center'>
              <button type='submit' className='mt-5 ' ><img alt=" " src={Search} className='customInput mr-0' /></button>
              </div>
              
            </form>
          </div>
            </div>
          <img alt=" " src={Banner} className='w-[100vw] h-[100vh] drop-shadow-xl' />
        </div>
        <div className='p-[5%]  bg-white drop-shadow-xl'>
          <h2 className='text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl sm:text-2xl xs:text-xl font-semibold text-left '>Trending Tours</h2>
          <div className='grid grid-cols-5 3xl:grid-cols-5 2xl:grid-cols-5 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-[3%] mt-[5%] 3xl:mt-[0] 2xl:mt-[0] xl:mt-[0] lg:mt-[0] md:mt-[10%] sm:mt-[10%] xs:mt-[10%] 3xl:p-0 2xl:p-0 xl:p-0 lg:p-0 md:pb-[55%] sm:pb-[55%] xs:pb-[60%]'>
            <button onClick={handleSearch} className=''>
            <div className=' rounded-xl overflow-hidden'>
              <img alt=" " src={Dubai} className='w-[500px] h-[200px] rounded-lg drop-shadow-xl hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125  hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125'/>
              </div>
              <p className='text-2xl 3xl:text-2xl 2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg xs:text-lg font-semibold mt-5'>United Arab Emirates</p>
            </button>
            <button onClick={handleSearch} className=''>
            <div className=' rounded-xl overflow-hidden'>
              <img alt=" " src={Cappadocia} className='w-[500px] h-[200px] rounded-lg drop-shadow-xl hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125  hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125'/>
             </div>
              <p className='text-2xl font-semibold 3xl:text-2xl 2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg xs:text-lg mt-5'>Cappadocia</p>
            </button>
            <button onClick={handleSearch} className=''>
            <div className=' rounded-xl overflow-hidden'>
              <img alt=" " src={switzerland} className='w-[500px] h-[200px] rounded-lg drop-shadow-xl hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125  hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125'/>
              </div>
              <p className='text-2xl font-semibold 3xl:text-2xl 2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg xs:text-lg mt-5'>Switzerland</p>
            </button>
            <button onClick={handleSearch} className=''>
            <div className=' rounded-xl overflow-hidden'>
              <img alt=" " src={Maldives} className='w-[500px] h-[200px] rounded-lg drop-shadow-xl hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125  hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125'/>
              </div>
              <p className='text-2xl font-semibold 3xl:text-2xl 2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg xs:text-lg mt-5'>Maldives</p>
            </button>
            <button onClick={handleSearch} className=''>
              <div className=' rounded-xl overflow-hidden'>
              <img alt=" " src={Norway} className='w-[500px] h-[200px] overflow-hidden rounded-lg drop-shadow-xl hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125  hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125'/>
              </div>
              <p className='text-2xl font-semibold 3xl:text-2xl 2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg xs:text-lg mt-5'>Norway</p>
            </button>
          </div>
        </div>
        <div className='p-[5%] px-[10%] flex 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col gap-[5%] poppins text-left items-center 3xl:mt-0 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-5 sm:mt-5 xs:mt-5'>
          <h4 className='text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-3xl md:text-xl sm:text-xl xs:text-xl w-[30%] 3xl:w-[30%] 2xl:w-[40%] xl:w-[40%] lg:w-[40%] md:w-[100%] sm:w-[100%] xs:w-[100%] font-semibold leading-[150%] 3xl:leading-[150%] 2xl:leading-[150%] xl:leading-[200%] lg:leading-[200%] md:leading-[200%] sm:leading-[200%] xs:leading-[200%]'>Where Relaxation Meets Family-Friendly Bliss, Your Perfect Vacation Awaits</h4>
          <p className='text-2xl 3xl:text-2xl 2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-lg xs:text-sm w-[60%] 3xl:w-[60%] 2xl:w-[60%] xl:w-[60%] lg:w-[60%] md:w-[100%] sm:w-[100%] xs:w-[100%]  3xl:mt-0 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-5 sm:mt-5 xs:mt-5 italic leading-[200%] 3xl:leading-[200%] 2xl:leading-[200%] xl:leading-[200%] lg:leading-[200%] md:leading-[200%] sm:leading-[200%] xs:leading-[200%]'>Embark on the ultimate adventure with our enchanting vacation packages. Dive into relaxation, bask in sun-kissed moments, and let the rhythm of exploration guide your journey. Unwind in luxurious retreats, where every sunset paints a new memory. Your dream vacation begins here, where each day is a masterpiece and relaxation is an art. Pack your bags; paradise awaits!</p>
        </div>
        <div className='bg-white py-[5%] drop-shadow-xl px-[5%] '>
          <div className='flex justify-between items-center 3xl:mt-[0%] 2xl:mt-[0%] xl:mt-[0%] lg:mt-[0%] md:mt-[10%] sm:mt-[10%] xs:mt-[10%] '>
          <h2 className='text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl sm:text-2xl xs:text-xl font-semibold text-left '>Inbound Tour UAE</h2>
          <button className='text-black' onClick={goToInboundTours}>See More &nbsp; >> </button>
          </div>
        <div className='mt-[5%] 3xl:mt-[5%] 2xl:mt-[5%] xl:mt-[5%] lg:mt-[5%] md:mt-[10%] sm:mt-[10%] xs:mt-[10%] grid grid-cols-5 3xl:grid-cols-5 2xl:grid-cols-5 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-[3%]  3xl:p-0 2xl:p-0 xl:p-0 lg:p-0 md:pb-[55%] sm:pb-[55%] xs:pb-[60%]' >
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={DesertSafari} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:[250px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl drop-shadow-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Desert Safari</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={FerrariWorld} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl ' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Ferrari World</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl 3xl:block 2xl:block xl:hidden lg:hidden md:block sm:block xs:block'>
              <img alt=" " src={WarnerBros} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Warner Bros</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl 3xl:block 2xl:block xl:block lg:hidden md:block sm:block xs:block'>
              <img alt=" " src={BurjKhalifa} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Burj Khalifa</p>
              </div>
            </div>
            <div className='bg-white rounded-xl drop-shadow-xl block'>
              <img alt=" " src={UnderwaterZoo} className='w-[700px] h-[200px] 3xl:h-[200px] 2xl:h-[200px] xl:h-[200px] lg:h-[200px] rounded-t-xl rounded-t-xl' />
              <div className='poppins text-left px-[5%] py-5 '>
                <p className='text-2xl 3xl:text-2xl 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm xs:text-sm   font-semibold'>Underwater Zoo</p>
              </div>
            </div>
          </div>
          </div>
        <div className=' p-[5%] 3xl:pb-[5%] 2xl:pb-[5%] xl:pb-[5%] lg:pb-[5%] md:pb-[10%] sm:pb-[10%] xs:pb-[10%]'>
        <h2 className='text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-2xl sm:text-2xl xs:text-xl font-semibold text-left'>Trending Tours</h2>
          <div className='grid grid-cols-5 3xl:grid-cols-5 2xl:grid-cols-5 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1  gap-[3%]  mt-[5%]'>
            <button onClick={handleSearch} className='relative drop-shadow-lg overflow-hidden rounded-xl'>
            <img alt=" " src={Abudhabi} className='w-[500px] h-[200px] rounded-xl  hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125  hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125' />
            <p className='text-xl text-white absolute top-[75%]  flex left-[33%]'>Abudhabi</p>
            </button>
            <button onClick={handleSearch} className='relative drop-shadow-lg overflow-hidden rounded-xl'>
            <img alt=" " src={Georgia} className='w-[500px] h-[200px] rounded-xl  hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125  hover:opacity-40 transition-transform duration-300 transform scale-100 hover:scale-125' />
            <p className='text-xl text-white absolute top-[75%] flex left-[38%] '>Georgia</p>
            </button>
            <button onClick={handleSearch} className='relative drop-shadow-lg overflow-hidden rounded-xl'>
            <img alt=" " src={Thailand} className='w-[500px] h-[200px] rounded-xl  hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125' />
            <p className='text-xl text-white absolute top-[75%] flex left-[33%]'>Thailand</p>
            </button>
            <button onClick={handleSearch} className='relative drop-shadow-lg overflow-hidden rounded-xl'>
            <img alt=" " src={Bali} className='w-[500px] h-[200px] rounded-xl  hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125' />
            <p className='text-xl text-white absolute top-[75%] flex left-[33%]'>Indonesia</p>
            </button>
            <button onClick={handleSearch} className='relative drop-shadow-lg overflow-hidden rounded-xl'>
            <img alt=" " src={Saudi} className='w-[500px] h-[200px] rounded-xl  hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125' />
            <p className='text-xl text-white absolute top-[75%] flex left-[30%]'>Saudi Arabia</p>
            </button>
            <button onClick={handleSearch} className='relative drop-shadow-lg 3xl:hidden 2xl:hidden xl:block lg:block md:block sm:block xs:block overflow-hidden rounded-xl'>
            <img alt=" " src={Spain} className='w-[500px] h-[200px] rounded-xl  hover:opacity-80 transition-transform duration-300 transform scale-100 hover:scale-125' />
            <p className='text-xl text-white absolute top-[75%] flex left-[40%]'>Spain</p>
            </button>
            </div>
        </div>
        <div className='flex 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col items-center justify-center p-[5%] gap-[5%] bg-white drop-shadow-xl'>
          <img alt=" " src={Vacation} className='w-[500px] 3xl:w-[500px] 2xl:w-[400px] xl:w-[450px] lg:w-[350px]  rounded-xl drop-shadow-xl ' />
          <div className='w-[50%] 3xl:w-[50%] 2xl:w-[50%] xl:w-[50%] lg:w-[50%] md:w-[100%] sm:w-[100%] xs:w-[100%] text-left 3xl:mt-0 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-5 sm:mt-5 xs:mt-5 3xl:px-0 2xl:px-0 xl:px-0 lg:px-0 md:px-[6%] sm:px-[8%] xs:px-[10%]'>
            <h2 className='text-2xl 3xl:text-2xl 2xl:text-2xl xl:text-xl lg:text-lg mt-5 text-slate-500 italic'>Inspiration</h2>
            <h2 className='text-4xl 3xl:text-4xl 2xl:text-3xl xl:text-2xl lg:text-xl md:text-xl sm:text-lg xs:text-lg leading-[150%] mt-5 font-[30px] italic 3xl:leading-[150%] 2xl:leading-[150%] xl:leading-[200%] lg:leading-[150%] md:leading-[200%] sm:leading-[200%] xs:leading-[200%] '>Step into serenity, where each moment whispers rejuvenation. Your escape to tranquility starts now – enjoy the journey!</h2>
            <p className='text-2xl 3xl:text-2xl 2xl:text-xl xl:text-lg lg:text-lg md:text-lg sm:text-sm xs:text-sm leading-[200%]  font-extralight mt-5 italic 3xl:leading-[200%] 2xl:leading-[200%] xl:leading-[200%] lg:leading-[175%] md:leading-[200%] sm:leading-[200%] xs:leading-[200%] '>Embark on your vacation with the intention of self-discovery and relaxation. May each moment be a brushstroke of bliss on the canvas of your rejuvenation. Enjoy the soothing rhythm of nature and the joy of simplicity. Bon voyage to a journey of well-deserved rest and renewal!</p>
            <button onClick={handleSearch} className='bg-[#0077b3] w-[30%] 3xl:w-[30%] 2xl:w-[40%] xl:w-[50%] lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] whitespace-nowrap text-white text-lg 3xl:text-lg 2xl:text-lg xl:text-lg lg:text-sm md:text-sm sm:text-sm xs:text-xs drop-shadow-xl  mt-[5%] flex items-center px-5 gap-5 justify-center '>Enquire Now <img alt=" " src={Arrow} className='w-[60px]' /></button>
             </div>
        </div>
        
      </div>
    );
  }
  
  export default TourPage;