// UserContext.js
import { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState({
    first_name: null,
    last_name: null,
    email: null,
  });

  useEffect(() => {
    // Retrieve userData from local storage on component mount
    const storedUserData = JSON.parse(localStorage.getItem('userData'));
    if (storedUserData) {
      setUserData(storedUserData);
    }
  }, []);

  const setUser = (first_name, last_name, email) => {
    const newUserData = { first_name, last_name, email };
    setUserData(newUserData);

    // Store userData in local storage
    localStorage.setItem('userData', JSON.stringify(newUserData));
  };

  return (
    <UserContext.Provider value={{ userData, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
