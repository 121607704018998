import Airport from './../../assets/home-page/airport-pic.jpg';
import Expertise from './../../assets/home-page/expertise.png';
import Transparency from './../../assets/home-page/transparency.png';
import Personalization from './../../assets/home-page/customization.png';
import Performance from './../../assets/home-page/performance.png';
import Support from './../../assets/home-page/support.png';
function WhyUs() {
    return (
      <div className="mt-[2%] px-[10%] py-[8%] flex gap-5 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-col md:flex-col sm:flex-col xs:flex-col dark:text-[#000000] cursive ">
        <img alt=" " src={Airport}  className='relative w-[50%] 3xl:w-[50%] 2xl:w-[45%] xl:w-[40%]  3xl:block 2xl:block xl:block lg:hidden md:hidden sm:hidden xs:hidden '/>
        <div className='text-right w-[50%] 3xl: w-[60%] 2xl:w-[60%] xl:w-[60%] lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%]'>
            <h2 className='text-5xl 3xl:text-4xl 2xl:text-4xl xl:text-2xl lg:text-2xl md:text-lg sm:text-md xs:text-sm cursive font-semibold'>Why Us</h2>
            <p className='cursive text-2xl 3xl:text-xl 2xl:text-xl xl:text-lg lg:text-md md:text-sm sm:text-xs xs:text-xs mt-[5%] lg:indent-32 md:indent-16 sm:indent-16 xs:indent-16 indent-48 leading-[200%] mt-5'>Your trust is paramount. Experience transparent, worry-free travel with us. Our inclusive package covers visa applications, personalized tours, hotel stays, and seamless flight bookings, ensuring a reliable, smooth, and memorable journey.</p>
        </div>
        <div className='drop-shadow-xl dark:drop-shadow-sm 3xl:absolute 2xl:absolute xl:absolute w-[80%] 3xl:w-[90%] 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] bg-white left-[15%] 3xl:left-[5%] 2xl:left-[0%] xl:left-[0%] lg:left-[0%] md:left-[0%] sm:left-[0%] xs:left-[0%]  bg-opacity-90 mt-[18%] 3xl:mt-[18%] 2xl:mt-[18%] xl:mt-[25%] lg:mt-[10%] md:mt-[15%] sm:mt-[10%] py-[5%] px-[3%] 3xl:py-[5%] 3xl:px-[3%] 2xl:py-[2%] xl:py-[5%] 2xl:px-[5%] lg:px-[10%]  flex 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-col md:flex-col sm:flex-col xs:flex-col gap-[5%] justify-center'>
            <div className='flex flex-col justify-end items-center gap-5'>
                <img alt=" " src={Expertise}  className='w-[75%] 3xl:w-[75%] 2xl:w-[60%] xl:w-[75%] lg:w-[15%] md:w-[25%] sm:w-[25%] xs:w-[25%]'/>
                <h5 className='text-2xl 3xl:text-2xl 2xl:text-2xl xl:text-xl lg:text-lg sm:text-lg xs:text-sm '>Expertise</h5>
                <p className='text-lg 3xl:text-lg 2xl:text-md xl:text-md lg:text-sm sm:text-xs xs:text-xs 3xl:block 2xl:block xl:hidden lg:block md:block sm:block xs:block'>Your travel experts, crafting seamless journeys with precision and passion, from visas to personalized itineraries.</p>
            </div>
            <div className='flex flex-col justify-end items-center'>
            <img alt=" " src={Transparency} className='w-[65%] 3xl:w-[65%] 2xl:w-[50%] xl:w-[47%] lg:w-[15%] md:w-[25%] sm:w-[25%] xs:w-[25%] '/>
                <h5 className='text-2xl 3xl:text-2xl 2xl:text-2xl xl:text-xl lg:text-lg sm:text-lg xs:text-sm mt-[14%] lg:mt-[1%] md:mt-[1%] xl:mt-[10%]'>Transparency</h5>
                <p className='text-lg 3xl:text-lg 2xl:text-md xl:text-md lg:text-sm sm:text-xs xs:text-xs 3xl:block 2xl:block xl:hidden lg:block md:block sm:block xs:block  mt-5'>transparency is our commitment—every step of your journey is clear, ensuring trust and confidence in your travel experience..</p>
            </div>
            <div className='flex flex-col justify-end items-center'>
            <img alt=" " src={Personalization} className='w-[50%] 3xl:w-[50%] 2xl:w-[35%] xl:w-[28%] lg:w-[10%] md:w-[20%] sm:w-[25%] xs:w-[25%]'/>
                <h5 className='text-2xl 3xl:text-2xl 2xl:text-2xl xl:text-xl lg:text-lg sm:text-lg xs:text-sm mt-[33%] lg:mt-[5%]'>Personalization</h5>
                <p className='text-lg 3xl:text-lg 2xl:text-md xl:text-md lg:text-sm sm:text-xs xs:text-xs 3xl:block 2xl:block xl:hidden lg:block md:block sm:block xs:block mt-5'>Experience the art of personalization with Shaliha Tourism—tailoring every aspect of your journey to reflect your unique preferences and desires.</p>
            </div>
            <div className='flex flex-col justify-end items-center'>
            <img alt=" " src={Performance} className='w-[40%] 3xl:w-[40%] 2xl:w-[30%] xl:w-[28%] lg:w-[10%] md:w-[20%] sm:w-[25%] xs:w-[25%]'/>
                <h5 className='text-2xl 3xl:text-2xl 2xl:text-2xl xl:text-xl lg:text-lg sm:text-lg xs:text-sm text-nowrap mt-[15%] lg:mt-[3%]'>Proven Track Record</h5>
                <p className='text-lg 3xl:text-lg 2xl:text-md xl:text-md lg:text-sm sm:text-xs xs:text-xs 3xl:block 2xl:block xl:hidden lg:block md:block sm:block xs:block mt-5'>Choose Shaliha Tourism with confidence, backed by a proven track record of delivering exceptional travel experiences and customer satisfaction.</p>
            </div>
            <div className='flex flex-col justify-end items-center'>
            <img alt=" " src={Support} className='w-[40%] 3xl:w-[40%] 2xl:w-[30%] xl:w-[32%] lg:w-[10%] md:w-[20%] sm:w-[25%] xs:w-[25%]'/>
                <h5 className='text-2xl 3xl:text-2xl 2xl:text-2xl xl:text-xl lg:text-lg sm:text-lg xs:text-sm text-nowrap mt-[14%] lg:mt-[3%]'>Dedicated Support</h5>
                <p className='text-lg 3xl:text-lg 2xl:text-md xl:text-md lg:text-sm sm:text-xs xs:text-xs 3xl:block 2xl:block xl:hidden lg:block md:block sm:block xs:block mt-5'>Count on Shaliha Tourism for dedicated support, ensuring a seamless and stress-free travel experience.</p>
            </div>
        </div>
      </div>
    );
  }
  
  export default WhyUs;
  