import React from 'react';
import LogoName from './../../assets/logo-name.jpg';
import Login from './../../assets/login-white.svg';
import Home from './../../assets/icons/header-icons/home-blue.svg';
import AboutUs from './../../assets/icons/header-icons/about-us-icon.svg';
import Flight from './../../assets/icons/header-icons/flight-blue.svg';
import Visa from './../../assets/icons/header-icons/visa-con-blue.svg';
import Hotels from './../../assets/icons/header-icons/hotels-blue-icon.svg';
import Tour from './../../assets/icons/header-icons/holiday-vacation-icon.svg';
import Yacht from './../../assets/icons/header-icons/yacht.png';
// import Blog from './../../assets/icons/header-icons/web-app-icon.svg';
import Menu from './../../assets/icons/header-icons/more.png';
import Close from './../../assets/icons/header-icons/close.png';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import AuthService from '../authService';
import { useUser } from '../../userContext';
import Logout from './../../assets/login/logout.png'
function TopHeader() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const navigate = useNavigate();
    const { userData, setUser } = useUser();
    const [activeLink, setActiveLink] = useState('');
    const cardRef = useRef(null);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }
    const toggleProfile = () => {
        setProfileOpen(!profileOpen)
    }
    const closeMenu = () => {
        setMenuOpen(false);
    }

    const goToLogin = () => {
        navigate('/login');
    };
    const goToHome = (link) => {
        navigate('/');
        setActiveLink(link)
    };

    const goToAbout = (link) => {
        navigate('/about-us');
        setActiveLink(link)
    };

    const goToFlights = (link) => {
        navigate('/flights');
        setActiveLink(link)
    };
    const goToVisa = (link) => {
        navigate('/visa');
        setActiveLink(link)
    };

    const goToHotel = (link) => {
        navigate('/hotel');
        setActiveLink(link)
    };

    const goToCruise = (link) => {
        navigate('/cruise');
        setActiveLink(link)
    };

    const goToTour = (link) => {
        navigate('/tour');
        setActiveLink(link)
    };

    // const goToBlog = (link) => {
    //     window.open(link, '_blank');
    // };

    
    const handleLogout = () => {
        // Remove the token from local storage
        AuthService.removeToken();
        setUser(null);
        localStorage.removeItem('userData');
        // Reload the page
        window.location.reload();
    };

    const isLoggedIn = AuthService.getToken();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (cardRef.current && !cardRef.current.contains(event.target)) {
                setProfileOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div className="relative bg-white flex items-center justify-between drop-shadow-lg z-10">
            <img   src={LogoName} alt="logo name" className='w-[13%] 3xl:w-[13%] 2xl:w-[13%] xl:w-[17%] lg:w-[17%] md:w-[25%] sm:w-[25%] xs:w-[25%]' />
            <div className=''>
                {/* <ul className='flex gap-5 justify-between items-center absolute left-[30%] top-[35%] z-10 text-[#62a3eb] text-sm text-white'> */}
                <ul className='flex gap-5 w-[30%] 3xl:flex 2xl:flex xl:flex 3xl:w-[25%] 2xl:w-[20%] xl:w-[15%] md:w-[5%] justify-between items-center absolute left-[30%] top-[50%] md:left-[10%] md:top-[40%] lg:hidden md:hidden sm:hidden xs:hidden xl:left-[23%] xl:top-[50%] 2xl:left-[28%] 2xl:top-[50%] 3xl:left-[35%] 3xl:top-[55%] z-10 text-[#62a3eb] text-sm 3xl:text-sm xl:text-xs lg:text-xs md:text-xs text-white'>

                    <li className={activeLink === 'home' ? 'py-5 lg:px-[4%] lg:py-[5%] xl:px-[6%] xl:py-[6%] 2xl:py-[5.3%] 3xl:py-[4.5%] 3xl:px-[5%] bg-[#3D85C6] scale-[100%] border-[#ffffff] border-2 rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col gap-2 justify-end translate-x-4 hover:scale-[90%]' : 'py-5 lg:px-[4%] lg:py-[5%] xl:px-[6.3%] xl:py-[6.6%] 2xl:py-[5.3%] 3xl:px-[4.5%] 3xl:py-[4.3%] bg-[#3D85C6] scale-[100%]  rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col gap-2 justify-end translate-x-4 hover:scale-[90%]'}>

                        <button className='' onClick={(e) => { e.preventDefault(); goToHome('home') }}><img  alt=" " src={Home} className='mx-auto w-[17.5%] xl:w-[45%] lg:w-[40%] md:w-[40%] ' /> Home</button></li>
                    <li className={activeLink === 'about' ? 'px-[3%] py-[3.5%] 3xl:py-[4.9%] 3xl:px-[4%] 2xl:px-[3.3%] 2xl:py-[6.2%] xl:px-[2%] xl:py-[8%] bg-[#3D85C6] text-xs rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col gap-1 justify-end scale-95 translate-x-4 hover:scale-100 border-[#ffffff] border-2' : 'px-[3%] py-[3.5%] 3xl:py-[4.9%] 3xl:px-[4%] 2xl:px-[3.3%] 2xl:py-[6%] xl:px-[2%] xl:py-[8%] bg-[#3D85C6] text-xs rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col gap-1 justify-end scale-95 translate-x-4 hover:scale-100'}>

                        <button onClick={(e) => { e.preventDefault(); goToAbout('about') }} className='text-nowrap'><img  alt=" " src={AboutUs} className='mx-auto w-[70%] 3xl:w-[70%] 2xl:w-[60%] xl:w-[55%]' />About Us</button></li>
                    <li className={activeLink === 'flight' ? 'py-4 lg:px-[3.3%] lg:py-[3.9%] xl:px-[6.8%] xl:py-[6.2%] 3xl:px-[4.7%] 3xl:py-[3.3%] 2xl:px-[6%] 2xl:py-[3.4%] bg-[#3D85C6]  rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col justify-end scale-95 translate-x-4 hover:scale-100 border-[#ffffff] border-2' : 'py-4 lg:px-[3.3%] lg:py-[3.9%] xl:px-[6.8%] xl:py-[6.2%] 3xl:px-[4.7%] 3xl:py-[3.3%] 2xl:px-[5.4%] 2xl:py-[3.4%] bg-[#3D85C6]  rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col justify-end scale-95 translate-x-4 hover:scale-100'}>

                        <button className='' onClick={(e) => { e.preventDefault(); goToFlights('flight') }}><img  alt=" " src={Flight} className='mx-auto w-[27%] xl:w-[58%] 3xl:w-[80%] 2xl:w-[81.5%] lg:w-[50%]' />Flights</button></li>
                    <li className={activeLink === 'visa' ? ' py-5 lg:py-[4.3%] lg:px-[6.9%] xl:px-[11.4%] xl:py-[6%] 2xl:px-[7.9%] 2xl:py-[4.5%] 3xl:px-[5.7%] 3xl:py-[4.1%] bg-[#3D85C6]  rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col justify-end scale-95 translate-x-4 hover:scale-100 border-[#ffffff] border-2' : 'py-5 lg:py-[4.3%] lg:px-[6.9%] xl:px-[11.4%] xl:py-[6%] 2xl:px-[7.9%] 2xl:py-[4.5%] 3xl:px-[5.7%] 3xl:py-[4.1%] bg-[#3D85C6]  rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col justify-end scale-95 translate-x-4 hover:scale-100'}>

                        <button onClick={(e) => { e.preventDefault(); goToVisa('visa') }}><img  alt=" " src={Visa} className='mx-auto w-[19%] 2xl:w-[92%] xl:w-[80%] 3xl:w-[80%] lg:w-[60%]' />Visa</button></li>
                    <li className={activeLink === 'hotel' ? ' py-3 lg:py-[3.2%] lg:px-[3.8%] xl:px-[6.9%] xl:py-[4.9%] 3xl:px-[4.5%] 3xl:py-[3.4%] 2xl:px-[5.7%] 2xl:py-[3.2%] bg-[#3D85C6]  rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col justify-end scale-95 translate-x-4 hover:scale-100 border-[#ffffff] border-2' : ' py-3 lg:py-[3.2%] lg:px-[3.8%] xl:px-[6.9%] xl:py-[4.9%] 3xl:px-[4.5%] 3xl:py-[3.4%] 2xl:px-[5.7%] 2xl:py-[3.2%] bg-[#3D85C6]  rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col justify-end scale-95 translate-x-4 hover:scale-100'}>

                        <button onClick={(e) => { e.preventDefault(); goToHotel('hotel') }}><img  alt=" " src={Hotels} className='mx-auto w-[35%] xl:w-[65%] 3xl:w-[80%] 2xl:w-[85%] lg:w-[60%] ' />Hotels</button></li>
                    <li className={activeLink === 'tour' ? ' py-4 lg:px-[5%] lg:py-[4.5%] xl:px-[8.2%] xl:py-[7%] 3xl:px-[5%] 3xl:py-[4.2%] 2xl:px-[6.5%] 2xl:py-[5%] bg-[#3D85C6]  rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col justify-end scale-95 translate-x-4 hover:scale-100 border-[#ffffff] border-2' : 'py-4 lg:px-[5%] lg:py-[4.5%] xl:px-[8.2%] xl:py-[7%] 3xl:px-[5%] 3xl:py-[4.2%] 2xl:px-[6.5%] 2xl:py-[5%] bg-[#3D85C6]  rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col justify-end scale-95 translate-x-4 hover:scale-100'}>

                        <button onClick={(e) => { e.preventDefault(); goToTour('tour') }}><img  alt=" " src={Tour} className='mx-auto w-[38%] xl:w-[85%] 3xl:w-[100%] lg:w-[70%] 2xl:w-[100%]' />Tours</button></li>
                    <li className={activeLink === 'cruise' ? ' py-4 lg:px-[5%] lg:py-[4.5%] xl:px-[6.2%] xl:py-[3%] 3xl:px-[4.5%] 3xl:py-[2.8%] 2xl:px-[5.6%] 2xl:py-[3%] bg-[#3D85C6]  rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col justify-end scale-95 translate-x-4 hover:scale-100 border-[#ffffff] border-2' : 'py-4 lg:px-[5%] lg:py-[4.5%] xl:px-[6.2%] xl:py-[3%] 3xl:px-[4.5%] 3xl:py-[2.8%] 2xl:px-[5.6%] 2xl:py-[3%] bg-[#3D85C6]  rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col justify-end scale-95 translate-x-4 hover:scale-100'}>

                        <button onClick={(e) => { e.preventDefault(); goToCruise('cruise') }}><img  alt=" " src={Yacht} className='mx-auto w-[38%] xl:w-[82%] 3xl:w-[100%] lg:w-[70%] 2xl:w-[88%] xl:w-[75%]' />Cruise</button></li>
                    {/* <li className={activeLink === 'blog' ? ' px-[5.8%] py-[2.5%] 2xl:py-[4.3%] 2xl:px-[7.5%] 3xl:px-[5.7%] 3xl:py-[3.8%] xl:px-[10.3%] xl:py-[4.5%] bg-[#3D85C6]  rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col justify-end scale-95 translate-x-4 hover:scale-100 border-[#ffffff] border-2' : 'px-[5.8%] py-[2.5%] 2xl:py-[4.3%] 2xl:px-[7.5%] 3xl:px-[5.4%] 3xl:py-[3.8%] xl:px-[10.3%] xl:py-[4.5%] bg-[#3D85C6]  rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col justify-end scale-95 translate-x-4 hover:scale-100'}>

                        <button onClick={(e) => { e.preventDefault(); goToBlog('blog') }}><img  alt=" " src={Blog} className='mx-auto w-[100%] 3xl:w-[100%] 2xl:w-[95%]' />Blog</button></li> */}
                    {/* <li className=' px-[5.3%] py-[2.2%] 2xl:py-[3.7%] 2xl:px-[6.5%] 3xl:px-[5.3%] 3xl:py-[3.6%] xl:px-[9%] xl:py-[3.8%] bg-[#3D85C6]  rounded-xl drop-shadow-2xl dark:drop-shadow-lg flex flex-col justify-end scale-95 translate-x-4 hover:scale-100'>

                        <button><img  alt=" " src={More} className='mx-auto w-[100%] 3xl:w-[100%]' />More</button></li> */}
                </ul>
            </div>
            <div className='me-[30px] md:me-[1%]'>
                <ul className='flex gap-2 items-center'>

                    {isLoggedIn ? (
                        <>
                            <li className='text-[#3D85C6] font-semibold text-lg relative'><button onClick={toggleProfile} className='flex items-center text-xl 3xl:text-xl xl:text-lg lg:text-sm md:text-xs sm:text-xs xs:text-xs'>{userData && userData.first_name} &nbsp;<img   src={Login} alt='Call' className='w-[30px] 3xl:w-[30px] 2xl:w-[30px] xl:w-[30px] lg:w-[30px] md:w-[22px] sm:w-[22px] xs:w-[22px]' ></img></button></li>
                            <div ref={cardRef} className={profileOpen ? 'absolute top-[100%] w-[12%] right-[0%] bg-[#3D85C6]  z-10 text-white opacity-90 text-lg drop-shadow-xl' : 'hidden'}>
                                <ul className='leading-[200%] border-b-[1px] border-white p-[2%] py-[7%]'>
                                    <li>Hello, {userData && userData.first_name}  {userData && userData.last_name}!</li>
                                    <li>{userData && userData.email}</li>
                                </ul>
                                <button onClick={handleLogout} className='py-[7%] font-semibold text-xl 3xl:text-xl xl:text-lg lg:text-sm md:text-xs sm:text-xs xs:text-xs flex mx-auto items-center'>Logout &nbsp;&nbsp;<img  alt=" " src={Logout} width={20} /></button>
                            </div>
                        </>
                    ) : (
                        <button onClick={goToLogin} className='font-semibold text-xl 3xl:text-xl xl:text-lg lg:text-sm md:text-xs sm:text-xs xs:text-xs flex text-[#3D85C6]'><span className=' 3xl:w-[50%] lg:w-[30%] md:w-[20%] sm:w-[20%] xs:w-[20%]' ><img   src={Login} alt='profile' width={30} ></img> </span>&nbsp;Login</button>
                    )}

                    <li>
                        <button className='3xl:hidden 2xl:hidden xl:hidden relative ' onClick={toggleMenu}><img  alt=" " src={Menu} width={20} className='' /></button>
                        <div className={menuOpen ? 'block absolute top-0 z-50 right-0 bg-[#3D85C6] text-white w-[100%] p-5' : 'hidden'}>
                            <button className='block justify-end' onClick={closeMenu}><img  alt=" " src={Close} width={20} /></button>
                            <ul className='flex flex-col gap-5'>
                                <li><button onClick={goToHome}>Home</button></li>
                                <li><button onClick={goToAbout}>About Us</button></li>
                                <li><button onClick={goToFlights}>Flight</button></li>
                                <li><button onClick={goToVisa}>Visa</button></li>
                                <li><button onClick={goToHotel}>Hotel</button></li>
                                <li><button onClick={goToTour}>Holidays</button></li>
                                <li><button onClick={goToCruise}>Cruise</button></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
    );
}

export default TopHeader;