import ShareDocuments from './../../assets/icons/visa-page-images/share-documents.svg';
import Payment from './../../assets/icons/visa-page-images/payment.svg';
import Visa from './../../assets/icons/visa-page-images/get-visa.svg';
import Support from './../../assets/icons/visa-page-images/post-support.svg';
import Search from './../../assets/icons/visa-page-images/search.png';
import Plan from '../../assets/visa-page/tour.jpg';
import Italy from '../../assets/visa-page/italy.png';
import France from '../../assets/visa-page/france.png';
import Georgia from '../../assets/visa-page/georgia.png';
import Uae from '../../assets/visa-page/united-arab-emirates.png';
import Us from '../../assets/visa-page/united-states.png';
import Oman from '../../assets/visa-page/oman.png';
import Saudi from '../../assets/visa-page/saudi-arabia.png';
import Greece from '../../assets/visa-page/greece.png';
import './../../CssComponent/HomePageStyle.css'
import SideSocialMedia from '../SocialMedia/SocialMediaSide';
import TopHeader from './top_header';
import { Helmet } from 'react-helmet';
function VisaPage() {
 
  // Handle form submission
  const handleSearch = (event) => {
    
    event.preventDefault(); // Prevent default form submission behavior
    const phoneNumber = '+971544544971';
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    
    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };
  return (
    <div className="h-[100%] pb-[5%]">
       <Helmet>
        <title>Visa Application</title>
        <meta name="description" content="Explore visa process, visit visa, visa renewal, and new visa information." />
      </Helmet>
      <TopHeader/>
      <SideSocialMedia />
      <div className="text-left px-[4%] poppins dark:text-[#000000]">
        <div className='bg-white w-[100%] mx-auto mt-[5%] p-[5%] px-[8%] 3xl:p-[5%] 2xl:p-[5%] xl:p-[5%] lg:p-[5%] md:p-[0%] sm:p-0 xs:p-0 rounded-xl opacity-90 drop-shadow-xl dark:drop-shadow-lg dark:drop-shadow-sm dark:drop-shadow-sm dark:drop-shadow-sm'>
          <div className='flex justify-center gap-[5%] 3xl:flex-row 2xl:flex-row xl:flex-col lg:flex-col md:flex-col-reverse sm:flex-col-reverse xs:flex-col-reverse'>
            <div className='poppins w-[50%] 3xl:w-[50%] 2xl:w-[55%] xl:w-[100%] lg:w-[100%] md:w-[100%] sm:w-[100%] xs:w-[100%] 3xl:p-0 2xl:p-0 xl:p-0 lg:p-0 md:px-[8%] md:p-[5%] sm:px-[10%] sm:p-[5%] xs:px-[10%] xs:p-[5%] leading-[200%]'>
              <h1 className="leading-[200%] font-semibold text-[#3D85C6] mt-[5%] text-5xl 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-lg xs:text-sm">Apply Visa</h1>
              <p className="leading-[200%] 3xl:leading-[200%] 2xl:leading-[200%] xl:leading-[200%] lg:leading-[200%] md:leading-[200%] sm:leading-[200%] xs:leading-[200%] mt-[5%] text-3xl 3xl:text-2xl 2xl:text-xl xl:text-lg lg:text-lg md:text-md sm:text-sm xs:text-xs mt-[1%] leading-[150%]">Ready to jet-set across the globe? Say goodbye to visa hassles! Our one-stop visa service takes the stress out of travel planning. Apply effortlessly, explore endlessly. Your global adventure starts here!</p>
              <div className='bg-white opacity-80 mx-auto mt-[5%] w-[60%] 3xl:w-[70%] 2xl:w-[80%] xl:w-[75%] lg:w-[70%] md:w-[95%] sm:w-[100%] xs:w-[100%]  p-[2%] 3xl:px-[0.7%] 2xl:px-[1%] xl:px-[1.2%] lg:px-[1.7%] md:px-[3%] sm:px-[4.5%] xs:px-[4.9%] rounded-full  drop-shadow-xl dark:shadow-[rgba(197,197,197) 1.95px 1.95px 2.6px]  px-[1%]'>
                <form className='flex justify-between' onSubmit={handleSearch}>
                  <input type='text' placeholder='Search Country' className='dark:bg-white text-xl 3xl:text-2xl 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs poppins w-full border-0 m-3' />
                  <button type='submit'><img alt=" " src={Search} className='customInput w-[70px] 3xl:w-[80px] 2xl:w-[75px] xl:w-[70px] lg:w-[70px] md:w-[60px] sm:w-[50px] xs:w-[50px]' /></button>
                </form>
              </div>
              <h3 className="text-3xl 3xl:text-3xl 2xl:text-3xl xl:text-2xl lg:text-xl md:text-lg sm:text-lg xs:text-sm mt-[10%] font-extralight text-[#3D85C6]">Visa Application Made simple</h3>
            </div>
            <div className='relative'>
              <img alt=" " src={Plan} className='w-[600px] 3xl:w-[900px] 2xl:w-[650px] xl:w-[100%] 3xl:mt-0 2xl:mt-0 xl:mt-[5%] lg:mt-[5%] md:mt-[0] sm:mt-[0] xs:mt-[0] rounded-xl drop-shadow-xl dark:drop-shadow-lg dark:drop-shadow-sm dark:drop-shadow-sm dark:drop-shadow-sm' />
              <div className='absolute top-0 grid grid-cols-2 gap-x-[40%] gap-y-[40%] 3xl:gap-x-[40%] 3xl:gap-y-[40%] 2xl:gap-x-[40%] 2xl:gap-y-[35%] xl:gap-x-[40%] xl:gap-y-[40%] lg:gap-x-[40%] lg:gap-y-[40%] md:gap-x-[25%] md:gap-y-[20%] sm:gap-x-[20%] sm:gap-y-[17%] xs:gap-x-[15%] xs:gap-y-[10%] p-[10%] w-[100%]'>
                <p className='bg-white opacity-85 px-5 py-1 justify-center  flex items-center text-xl 3xl:text-xl 2xl:text-sm xl:text-lg lg:text-sm md:text-xs sm:text-xs xs:text-xs font-semibold rounded-2xl w-[70%] 3xl:w-[70%] 2xl:w-[70%] xl:w-[50%] lg:w-[70%] md:w-[75%]'><img alt=" " src={Uae} className='w-[40px] 3xl:w-[40px] 2xl:w-[20px] xl:w-[30px] lg:w-[25px] md:w-[15px] sm:w-[15px] xs:w-[15px]' />&nbsp;UAE</p>
                <p className='bg-white opacity-85 px-5 py-1 justify-center  flex items-center text-xl 3xl:text-xl 2xl:text-sm xl:text-lg lg:text-sm md:text-xs sm:text-xs xs:text-xs font-semibold rounded-2xl w-[70%] 3xl:w-[70%] 2xl:w-[70%] xl:w-[60%] lg:w-[70%] md:w-[75%]'><img alt=" " src={Italy} className='w-[40px] 3xl:w-[40px] 2xl:w-[20px] xl:w-[30px] lg:w-[25px] md:w-[15px] sm:w-[15px] xs:w-[15px]' />&nbsp;Italy</p>
                <p className='bg-white opacity-85 px-5 py-1 justify-center  flex items-center text-xl 3xl:text-xl 2xl:text-sm xl:text-lg lg:text-sm md:text-xs sm:text-xs xs:text-xs font-semibold rounded-2xl w-[70%] 3xl:w-[70%] 2xl:w-[70%] xl:w-[70%] lg:w-[70%] md:w-[80%]'><img alt=" " src={France} className='w-[40px] 3xl:w-[40px] 2xl:w-[20px] xl:w-[30px] lg:w-[25px] md:w-[15px] sm:w-[15px] xs:w-[15px]' />&nbsp;France</p>
                <p className='bg-white opacity-85 px-[7%] py-2 justify-center  flex items-center text-xl 3xl:text-xl 2xl:text-sm xl:text-lg lg:text-sm md:text-xs sm:text-xs xs:text-xs font-semibold rounded-2xl w-[72%] 3xl:w-[72%] 2xl:w-[72%] xl:w-[80%] lg:w-[72%] md:w-[80%] whitespace-nowrap'><img alt=" " src={Georgia} className='w-[40px] 3xl:w-[40px] 2xl:w-[20px] xl:w-[30px] lg:w-[25px] md:w-[15px] sm:w-[15px] xs:w-[15px]' />&nbsp;Georgia</p>
                <p className='bg-white opacity-85 px-[7%] py-2 justify-center  flex items-center text-xl 3xl:text-xl 2xl:text-sm xl:text-lg lg:text-sm md:text-xs sm:text-xs xs:text-xs font-semibold rounded-2xl w-[100%] 3xl:w-[100%] 2xl:w-[100%] xl:w-[100%] lg:w-[70%] md:w-[100%] whitespace-nowrap'><img alt=" " src={Us} className='w-[40px] 3xl:w-[40px] 2xl:w-[20px] xl:w-[30px] lg:w-[25px] md:w-[15px] sm:w-[15px] xs:w-[15px]' />&nbsp;United States</p>
                <p className='bg-white opacity-85 px-[7%] py-2 justify-center  flex items-center text-xl 3xl:text-xl 2xl:text-sm xl:text-lg lg:text-sm md:text-xs sm:text-xs xs:text-xs font-semibold rounded-2xl w-[70%] lg:w-[70%] md:w-[80%] whitespace-nowrap '><img alt=" " src={Oman} className='w-[40px] 3xl:w-[40px] 2xl:w-[20px] xl:w-[30px] lg:w-[25px] md:w-[15px] sm:w-[15px] xs:w-[15px]' />&nbsp;Oman</p>
                <p className='bg-white opacity-85 px-[7%] py-2 justify-center  flex items-center text-xl 3xl:text-xl 2xl:text-sm xl:text-lg lg:text-sm md:text-xs sm:text-xs xs:text-xs font-semibold rounded-2xl w-[100%] lg:w-[70%] md:w-[100%] whitespace-nowrap'><img alt=" " src={Saudi} className='w-[40px] 3xl:w-[40px] 2xl:w-[20px] xl:w-[30px] lg:w-[25px] md:w-[15px] sm:w-[15px] xs:w-[15px]' />&nbsp;Saudi Arabia</p>
                <p className='bg-white opacity-85 px-[7%] py-2 justify-center  flex items-center text-xl 3xl:text-xl 2xl:text-sm xl:text-lg lg:text-sm md:text-xs sm:text-xs xs:text-xs font-semibold rounded-2xl w-[70%] lg:w-[70%] md:w-[80%] whitespace-nowrap'><img alt=" " src={Greece} className='w-[40px] 3xl:w-[40px] 2xl:w-[20px] xl:w-[30px] lg:w-[25px] md:w-[15px] sm:w-[15px] xs:w-[15px]' />&nbsp;Greece</p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-r from-[#2C69D1] to-[#0ABCF9] p-[3%] mt-[5%] rounded-xl relative overflow-hidden relative poppins grid justify-center">
          
          <h2 className='text-white text-3xl 3xl:text-3xl 2xl:text-2xl xl:text-xl lg:text-lg md:text-md sm:text-sm xs:text-xs text-center'>Your Hassle-Free Visa Application Starts Here</h2>
          <div className='mt-[3%] my-[2%] text-white text-center font-extralight flex flex-row 3xl:flex-row 2xl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col justify-center items-center gap-5' >
            <div className='grid justify-items-center w-[25%] 3xl:w-[25%] 2xl:w-[25%] xl:w-[25%] lg:w-[25%] md:w-[100%] sm:w-[100%] xs:w-[100%]'>
              <img alt=" " src={ShareDocuments} width={100} />
              <h6 className='text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs mt-[5%] whitespace-nowrap'>Share Your Documents</h6>
              <p className='text-lg 3xl:text-lg 2xl:text-md xl:text-sm lg:text-xs md:text-xs sm:text-xs xs:text-xs'>Our secure platform ensures safe handling and protection of your files.</p>
            </div>
            <div className='grid justify-items-center w-[25%] 3xl:w-[25%] 2xl:w-[25%] xl:w-[25%] lg:w-[25%] md:w-[100%] sm:w-[100%] xs:w-[100%]'>
              <img alt=" " src={Payment} width={120} />
              <h6 className='text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs'>Secure Payment</h6>
              <p className='text-lg 3xl:text-lg 2xl:text-md xl:text-sm lg:text-xs md:text-xs sm:text-xs xs:text-xs'>Streamline your payments online: Safe, simple, and secure.</p>
            </div>
            <div className='grid justify-items-center w-[25%] 3xl:w-[25%] 2xl:w-[25%] xl:w-[25%] lg:w-[25%] md:w-[100%] sm:w-[100%] xs:w-[100%]'>
              <img alt=" " src={Visa} width={100} />
              <h6 className='text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs mt-[5%]'>Visa Ease</h6>
              <p className='text-lg 3xl:text-lg 2xl:text-md xl:text-sm lg:text-xs md:text-xs sm:text-xs xs:text-xs'>Visa Granted: Explore Boundless Horizons, Securely in Your Hands.</p>
            </div>
            <div className='grid justify-items-center w-[25%] 3xl:w-[25%] 2xl:w-[25%] xl:w-[25%] lg:w-[25%] md:w-[100%] sm:w-[100%] xs:w-[100%]'>
              <img alt=" " src={Support} width={100} />
              <h6 className='text-xl 3xl:text-xl 2xl:text-lg xl:text-md lg:text-sm md:text-xs sm:text-xs xs:text-xs mt-[5%]'>Post-Visa Support</h6>
              <p className='text-lg 3xl:text-lg 2xl:text-md xl:text-sm lg:text-xs md:text-xs sm:text-xs xs:text-xs'>Beyond the Visa: Ongoing Assistance for Your Journey Ahead.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisaPage;